<template>
  <div
    class="page-header align-items-start min-vh-100"
  >
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="shadow-success border-radius-lg py-3 pe-1"
                style="background-image: linear-gradient(195deg,#4a92df,#2f68a5)"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Area partner Telephonevox 
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form action="#" method="POST" @submit.prevent="handleLogin">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control form-control-default"
                    placeholder="Email"
                    v-model="formData.email"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control form-control-default"
                    placeholder="Password"
                    v-model="formData.password"
                  />
                </div>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    style="background-image: linear-gradient(195deg,#cc0064,#7f0e53)"
                    :type="'submit'"
                    fullWidth
                    >Accedi
                  </material-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";

export default {
  name: "login",
  components: {
    // Navbar,
    // MaterialSwitch,
    MaterialButton,
  },
  data () {
      return {
          // toast: useToast(),
          formData: {
              email: "",
              password: ""
          },
          isLoading: false,
          error: null
      }
  },
  async beforeMount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
  },
  beforeUnmount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleLogin() {
        this.error = null;
        this.isLoading = true;
        try {
            await this.$store.dispatch('login', this.formData);
            await this.$router.push('/my_site/how_it_works');
        } catch (error) {
            this.error = error;
            //TODO add toast
        } finally {
            this.isLoading = false;
        }
    },
  },
};
</script>
