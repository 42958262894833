<template>
    <div 
        v-for="lang in Object.keys(speakerList)" 
        :key="lang"
    >
        <div v-if="languagesToShow.includes(lang)">
            <h4 class="font-md-lg">Speaker {{ getLanguageTitle(lang) }}</h4>
            <div class="intro">{{ sexToShow }}</div>
            <Radiobutton
                :el="speakerList[lang]"
                :inHalf="true"
                @update="updateSpeaker"
            />
        </div>
    </div>
</template>
    
<script>
import AudioPlayer from "./AudioPlayer.vue";
import Radiobutton from "@/components/Radiobutton.vue";
import { getItalianLanguageTitle }  from "@/assets/js/utils.js";
import { markRaw } from 'vue';

export default {
    name: "SpeakersResolverComponent",

    emits :{
        speakerUpdated: null
    },

    components: {
        Radiobutton,
    },

    props: {
        el: {
            type: Object,
            required: false,
            default: () => {}
        },
        typeToShow: {
            required: true,
            default: null
        },
        sexToShow: {
            required: true,
            default: null
        },
        languagesToShow: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            picked: [],
            speakerList: []
        }
    },

    methods: {
        updateSpeaker(e) {
            var correspondingSexLanguage = this.el[this.sexToShow];
            var selectedSpeaker = null;
            for (const [language, speakers] of Object.entries(correspondingSexLanguage)) {
                speakers.forEach(function (speaker) {
                    if (speaker.id == e) {
                        selectedSpeaker = {};
                        selectedSpeaker[language] = {speaker: speaker.id};
                    };
                });
            }

            if (selectedSpeaker) {
                this.$emit("speaker-updated", selectedSpeaker);
            }
        },
        getLanguageTitle(lang, capital = false) {
           return getItalianLanguageTitle(lang, capital);
        }
    },

    computed: {
        getHalf() {
            return Math.ceil(this.el.length / 2)
        }
    },

    watch: {
        picked(newValue) {
            this.$emit("picked", newValue);
        },
        languagesToShow() {
            if (this.sexToShow) {
                var that = this;
                Object.keys(this.el[this.sexToShow]).forEach(function callback(language) {
                    if (that.languagesToShow.includes(language)) {
                        that.el[that.sexToShow][language].forEach(function(part, index) {
                            if (this[index].num_voice > 0) {
                                this[index].component = markRaw(AudioPlayer);
                                this[index].componentProps = { id: `audio_${index + 100}`, preview: this[index].demo_link };
                            }
                        }, that.el[that.sexToShow][language])
                        that.speakerList[language] = that.el[that.sexToShow][language];
                    }
                });
            }
        },
        sexToShow(newV) {
            var that = this;
            Object.keys(this.el[newV]).forEach(function callback(language) {
                if (that.languagesToShow.includes(language)) {
                    that.el[newV][language].forEach(function(part, index) {
                        if (this[index].num_voice > 0) {
                            this[index].component = AudioPlayer;
                            this[index].componentProps = { id: `audio_${index + 100}`, preview: this[index].demo_link };
                        }
                    }, that.el[newV][language])
                    that.speakerList[language] = that.el[newV][language];
                }
            });
        }
    },
    
}
</script>
    
<style>

</style>