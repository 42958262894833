<template>
  <div
    class="header-partner-container"
  >
    <div
        class="logo-partner-container"
    >
      <div style="width: 50%">
        <img
          loading="lazy"
          class="custom-size"
          :src="getCurrentAffiliateLogo"
          alt="Logo / Immagine"
        />
      </div>
      <div
        style="
          width: 59%;
          display: flex;
          justify-content: end;
          align-items: center;
          vertical-align: middle;
          padding-right: 10px; ;
        "
      >
          <i class="material-icons-round opacity-10" style="margin-top:-5px;color:black;">call</i>&nbsp;&nbsp;<h5>{{  getCurrentAffiliateNumber }}</h5>
      </div>
    </div>
  </div>
  <div class="carousel-inner">
    <div class="item active">
      <div class="banner gradient positionRelative">
        <div class="banner-container">
          <div class="row row-xs-fix">
            <div class="col-sm-6 col-xs-reset">
              <img
                width="912"
                height="622"
                :src="require('@/assets/img/WL_cover.jpg')"
                alt="Testimonial Telephone Vox, speaker davanti a microfono professionale"
              />
            </div>
            <div class="col-sm-6 col-xs-reset">
              <div class="protected protected-right">
                <div class="quote valignNew">
                  <div class="featured-caption">
                    <h1 class="font-xl fraseTitoloBannerHome">
                      Registriamo messaggi per segreterie e centralini con la
                      voce di
                      <span class="color-accent">speaker professionisti</span>
                    </h1>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import repository from '../api/repository';


export default {
  name: "Header",
  props: {
    showImg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  async beforeCreate() {
    var { data } = await repository.getAffiliateInfos(this.$store.getters['affiliateCib']);
    await this.$store.dispatch('setAffiliateInfos', data);
  },

  computed: {
    getCurrentAffiliateLogo() {
      return this.$store.getters['currentAffiliateLogo'];
    },

    getCurrentAffiliateNumber() {
      return this.$store.getters['currentAffiliateNumber'];
    }
  }
};
</script>

<style>
.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top:78px;
}


.carousel-inner>.active {
    left: 0;
}

.banner.gradient {
    background: #dae2eb;
    background: -moz-radial-gradient(center,ellipse cover,#dae2eb 0,#dae2eb 9%,#dae2eb 22%,#919fae 100%);
    background: -webkit-radial-gradient(center,ellipse cover,#dae2eb 0,#dae2eb 9%,#dae2eb 22%,#919fae 100%);
    background: radial-gradient(ellipse at center,#dae2eb 0,#dae2eb 9%,#dae2eb 22%,#919fae 100%);
}
.positionRelative {
    position: relative;
}
.banner {
    position: relative;
}
element.style {
    background: url(/vendor/telephonevox-tp/img/bg-text.svg) no-repeat -5px -20px;
    display: none;
}
.boxScrittaStagionale {
    position: absolute;
    width: 300px;
    height: 40px;
    z-index: 1;
}

img.iconStagione {
    float: left;
    width: 20%;
}
.banner img, .banner picture {
    width: 100%;
}
img {
    max-width: 100%;
    height: auto;
}

img {
    vertical-align: middle;
}
img {
    border: 0;
}

.fraseDisplayStagione {
    float: right;
    width: 70%;
    line-height: 38px;
    text-align: center;
    margin-right: 28px;
    color: #e6e6e6;
    font-size: 15px;
}

.banner-container {
    position: relative;
}

.protected.protected-right {
    padding-right: 30px;
}

.valignNew {
    display: table-cell;
    padding-top: 15%;
}

.banner .intro {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: lighter;
    text-transform: uppercase;
}
.intro {
    margin-bottom: 15px;
    letter-spacing: 0;
    line-height: 130%;
    font-size: 14px;
}

.featured-caption {
    text-transform: none;
    color: #465a6e;
    padding-right: 10px;
}

.featured-caption .font-xl {
    text-transform: none;
}
.font-xl, h2 {
    font-size: 44px;
}
.font-lg, .font-md-lg, .font-xl, .font-xxl, h1, h2, h3 {
    font-weight: lighter;
    line-height: 130%;
    letter-spacing: -1px;
}

.header-partner-container {
    width: 100%;
    height: 80px;
    background-color: white;
    position: fixed;
    right: 16px;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 1px 1px 25px -10px #000;
}

.logo-partner-container {
    padding-left: 30px; 
    width: 100%; 
    display: flex;
    justify-content: space-between;
    padding-left:16px;
}

.custom-size {
  width: 310px;
  height: 80px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .header-partner-container {
        right: 0px;
  }

  .logo-partner-container {
    padding-left: 0px;
  }
}
</style>
