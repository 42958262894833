<template>
  <div id="text">
    <h3 class="color-accent">Testo del {{ getPositionMessageWord() }} messaggio</h3>
    <h4 class="intro">
      Scrivi il testo del {{ getPositionMessageWord() }} messaggio da registrare, usando la lingua che
      hai selezionato. Potrai inserire altri messaggi più avanti.
    </h4>
    <div class="intro">
      HAI BISOGNO DI SPUNTI PER SCRIVERE IL TUO MESSAGGIO DI CENTRALINO?
    </div>
    <div class="col-md-12 col-xs-12">
      <div class="input-group">
        <input
          type="text"
          disabled="disabled"
          class="form-control hidden-xs hidden-sm"
          value="Usa come modello un"
          style="background-color: white;
            border: 1px solid;
            border-color: #70849b;
            direction: rtl;
          "
        />
        <select class="dpd-presets" v-model="this.messageModel" @change="setMainMessageFromModel()">
          <option value="welcome">messaggio di benvenuto</option>
          <option value="privacy">messaggio di benvenuto con privacy</option>
          <option value="wait">messaggio di attesa</option>
          <option value="closed_offices">messaggio di uffici chiusi</option>
          <option value="busy_lines">messaggio di linee occupate</option>
          <option value="closed_holidays">messaggio di chiusura per ferie</option>
          <option value="tree">messaggio di smistamento ad albero</option>
          <option value="personal">messaggio di segreteria personale/domestica</option>
          <option value="covid">messaggio di uffici chiusi per COVID19</option>
        </select>
      </div>
    </div>
    <textarea
      v-model.lazy="mainMessageText"
      v-debounce="500"
      @focusin="checkTouched()"
      :placeholder="getPlaceHolderMainMessage"
      ref="mainMsg"
      class="mt-3"
      style="width: 100%"
      rows="3"
    ></textarea>
    <div class="text-price">
      <span id="prezzoMsg">
        <span id="dicituraLinguaIdent">Lingua identificata: </span>
        <img
          v-if="identifiedLanguage"
          id="bandieraMsgOriginale"
          style="margin-right: 5px"
          width="25"
          alt=""
          :src="require(`@/assets/img/flags/${identifiedLanguage}.svg`)" 
        />
        <span id="linguaMsgOriginale" style="margin-right: 5px">{{ getLanguageTitle(identifiedLanguage, true) }}</span>
        <strong class="price color-accent non-scontato">
          <span id="prezzoTestoUnicoNonScontato"></span>
        </strong>
        <strong class="price color-accent">
          <span id="prezzoTestoUnico">{{this.prices[identifiedLanguage] }}</span>
        </strong>
        <span class="hidden-sm hidden-md hidden-lg"><br /></span>
      </span>
    </div>
    <div v-if="languageHasBeenIdentified">
        <div 
            v-for="language in selectedLanguages.filter(lang => lang != identifiedLanguage)"
            v-bind:key="language"
        >
            <textarea
              v-if="!translating || manualTranslationByLanguage.includes(language)"
              :disabled="!manualTranslationByLanguage.includes(language)"
              v-model.lazy="translations[language]"
              v-debounce="500"
              @change="calculatePricesFrom()"
              class="mt-3"
              style="width: 100%"
              rows="3"
            ></textarea>
            <SkeletonMessageText
              v-else
              :width="100"
              :height="80"
              class="mt-3"
            />
            <Radiobutton
              :showHr="false"
              :el="[
                {
                  id: `${language}-automatic-translation`,
                  label: 'Traduzione automatica (Google Translate)',
                  selected: !manualTranslationByLanguage.includes(language),
                },
                {
                  id: `${language}-manual-translation`,
                  label: 'Traduzione manuale',
                  selected: manualTranslationByLanguage.includes(language)
                },
              ]"
              @update="updateTextAreaTranslation($event, language)"
            />
            <div class="text-price">
              <span id="prezzoMsg">
                <span id="dicituraLinguaIdent"></span>
                <img
                  id="bandieraMsgOriginale"
                  style="margin-right: 5px"
                  width="25"
                  alt=""
                  :src="require(`@/assets/img/flags/${language}.svg`)"
                />
                <span id="linguaMsgOriginale" style="margin-right: 5px">{{ getLanguageTitle(language, true) }}</span>
                <strong class="price color-accent non-scontato">
                  <span id="prezzoTestoUnicoNonScontato"></span>
                </strong>
                <strong class="price color-accent">
                  <span id="prezzoTestoUnico" v-if="prices[language]">{{ prices[language] }}</span>
                </strong>
                <span class="hidden-sm hidden-md hidden-lg"><br /></span>
              </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import repository from '../api/repository';
import { getPositionMessage, getMessageModel, getItalianLanguageTitle }  from "@/assets/js/utils.js"
import Radiobutton from './Radiobutton.vue';
import _ from "lodash";
import SkeletonMessageText from './SkeletonMessageText.vue';

export default {
  name: "MessagesTextComponent",

  components: {
    Radiobutton,
    SkeletonMessageText
},

  props: {
    selectedLanguages: {
        type: Object,
        required: true,
        default: () => {}
    },
    sex: {
      type: String,
      required: true,
      default: null
    },
    messageNumber: {
      type: Number,
      required: true,
      default: null
    },
    presetMessages: {
      type: Object,
      required: false,
      default: null
    },
    iF: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
        mainMessageText: "", // "Inserisci qui il tuo testo",
        touched: false,
        languageHasBeenIdentified: true,
        identifiedLanguage: null,
        translations: {},
        translating: false,
        mustTranslate: true,
        prices: {},
        messageModel: 'welcome', // Preselect like tvox
        manualTranslationByLanguage: [],
        searchDebounce: null
    }
  },

  async beforeMount()
  {
    if (!this.presetMessages || this.presetMessages.length <= 0) {
      var lang  = (await repository.getLanguageIdentified(this.mainMessageText)).data;
      this.identifiedLanguage = this.selectedLanguages.includes(lang) ? 
        lang :
        this.getNextAvailableLanguage(); 
    } else {
      this.checkTouched();
      this.mustTranslate = false;
      await this.setExistingTexts();
      this.mustTranslate = true;
    }
  },

  computed: {
    getPlaceHolderMainMessage() {
      return (this.touched ? "" : "Inserisci qui il tuo testo");
    }
  },

  methods: {
    async checkTouched() {
      if (!this.touched) {
        this.touched = true;
        this.$refs?.mainMsg?.blur()
        this.mainMessageText = '';
        await this.$nextTick()
        this.$refs.mainMsg.focus()
      }
    },

    async setExistingTexts() {
      this.identifiedLanguage = this.iF;
      this.mainMessageText = this.presetMessages[this.iF];
      delete this.presetMessages[this.identifiedLanguage];
      this.translations = this.presetMessages;
      // await this.calculatePrices();
    },

    async identifyLanguage() {
        this.languageHasBeenIdentified = false;
        await repository.identifyLanguage(this.mainMessageText);
        this.languageHasBeenIdentified = true;
    },

    async setIdentifiedLanguage() {
      if (this.mainMessageText != null) {
        // Force to be IT when language identified does not exists
        var lang  = (await repository.getLanguageIdentified(this.mainMessageText)).data.languageCode;
        this.identifiedLanguage = this.selectedLanguages.includes(lang) ? 
          lang :
          this.getNextAvailableLanguage(); 
      }
    },

    setMainMessageFromModel() {
      var messageModel = getMessageModel(this.messageModel);
      if (messageModel) {
        this.mainMessageText = messageModel;
      }
    },

    /**
     * Weird logic in my opinion but this is how it works in tvox
     * 
     * - it
     * - en
     * - first available language
     * 
     * TODO replace with constants
     */
    getNextAvailableLanguage() {
      if (this.selectedLanguages.includes("it")) {
        return "it";
      }
      if (this.selectedLanguages.includes("en")) {
        return "en";
      }
      return this.selectedLanguages[0] ?? null
    },

    async translateMessages() {

      if (Object.keys(this.selectedLanguages).length <= 1 ||
          !this.identifiedLanguage ||
          this.mainMessageText.length <= 0)
      {
        return;
      }




      this.translating = true;
      var selectedLanguageWithoutManuals = this.selectedLanguages.filter(x => !this.manualTranslationByLanguage.includes(x))
      var translations = (await repository.getTranslations(this.mainMessageText, this.identifiedLanguage, selectedLanguageWithoutManuals)).data; 
      this.translating = false;  
      Object.keys(translations).forEach((language) => this.translations[language] = translations[language].text);
    },

    async calculatePricesFrom()
    {
      await this.calculatePrices();
    },

    async calculatePrices()
    {
      // 2 - Get Prices
      let languageMap = this.getLanguageMap()

      let messagesObj = Object.assign({}, this.translations);
      messagesObj[this.identifiedLanguage] = this.mainMessageText;

      var {data} = await repository.getPricesText({ options: languageMap, messages: messagesObj, affiliateId: this.$store.getters['affiliateCib'] });
      this.prices = data.prices_by_language;

      this.$emit('messages-updated', {
        total_cost: data.real_cost, 
        messages: messagesObj, 
        without_discount: data.cost_without_discount, 
        identified_language: this.identifiedLanguage
      });
    },

    getLanguageMap()
    {
      const languageMap = {};

      languageMap["linguasito"] = "italiano";
      languageMap["linguaSitoSigla"] = "it";
      languageMap["linguaMessaggioOriginale"] = this.identifiedLanguage; // Change to iso
      languageMap["messaggioNumero"] = this.messageNumber;
      languageMap["totaleMessaggioCarello"] = this.messageNumber;
      languageMap["voce"] = this.sex;
      languageMap["numberoCaratteriPrimoMessaggio"] = 6;

      return languageMap;
    },

    getPositionMessageWord() {
      return getPositionMessage(this.messageNumber);
    },

    async calculateTexts() {
      try {
        if (this.mustTranslate && this.mainMessageText.length > 0) {
          await this.setIdentifiedLanguage();
          await this.translateMessages();
          await this.calculatePrices();
        }
      } catch (e) {
        console.error(e)
      }
    },

    async updateTextAreaTranslation(e, language) {
      if (e == `${language}-manual-translation`) {
        this.manualTranslationByLanguage = this.manualTranslationByLanguage.filter(x => x != language);
        this.manualTranslationByLanguage.push(language);
      } else {
        if (this.manualTranslationByLanguage.includes(language)) {
          this.manualTranslationByLanguage = this.manualTranslationByLanguage.filter(x => x != language);
          await this.calculateTexts();
        }
      }
    },

    getLanguageTitle(lang, capital = false) {
      return getItalianLanguageTitle(lang, capital);
    },
  },

  watch: {
    async mainMessageText() {
      await this.calculateTexts();      
    },

    selectedLanguages: _.debounce(async function(newV){
      // delete useless translations
      this.translations = Object.values(newV).reduce((a, k) => (a[k] = this.translations[k], a), {})
      await this.calculateTexts();
    }, 800),
  
    async sex() {
      await this.calculateTexts();
    }
  }

};

</script>


<style>
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .dpd-presets {
    width: 100%;
  }
}
</style>