<template>
    <span></span>
</template>

<script>
import repository from '../../api/repository';

export default {
    props: {
        openModal: {
            type: Boolean,
            required: true
        },
        typeModal: {
            type: String,
            required: true
        },
        model: { // TODO map to baseMusicalClass
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            openModalData: this.openModal,
            typeModalData: this.typeModal,
            contentList: [],
        }
    },

    async mounted() {
        if (this.isModalWithId()) {
            var {data} = await repository.getModalContent(this.typeModal);

            if (this.model) {
                var index = data.map(function(e) { return e.id; }).indexOf(parseInt(this.model.value.trim()));

                if (index >= 0) {
                    data[index].selected = true;
                }
            }

            this.contentList = data;
        } else {
            if (this.model?.value && (this.model.type == this.typeModal)) {
                this.formData = this.model.value;
            }
        }
    },

    methods: {
        isModalWithId() {
            return ['ClassicMusic', 'ModernMusic', 'ChristmasMusic'].includes(this.typeModal)
        },
        save(e) {
            if (this.isModalWithId() && (e.replace("musical_base_", "") != this.model?.value.replace("musical_base_", ""))) {
                this.$emit('filled', e.replace("musical_base_", ""));
                setTimeout(() => this.openModalData = false, 250);
            }
        }
    },

    watch: {
        openModal(newV) {
            this.$store.state.playedBase = 0;
            this.openModalData = newV;
        },
        typeModal(newV) {
            this.typeModalData = newV;
        }
    }
}
</script>