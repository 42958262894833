<template>
    <Modal 
      :show-modal="openModalData"
    >
      <template v-slot:header>
          Scegli la base
      </template>
      <template v-slot:body>
          Seleziona la base che desideri
      </template>
    </Modal>
  </template>
  
  <script>
    import Modal from '../../components/Modal.vue';
  import Base from './base.vue';
  
    export default {
      extends: Base,
      components: {
        Modal
      },
    }
  </script>
  
  