<template>
    <div v-if="messageNumber && currentMessage">
        <OrderPage
            :message="currentMessage"
            :edit="editMode"
        />
    </div>
</template>

<script>
import OrderPage from '../views/OrderPage.vue';
import Message from '@/classes/Message.js';

export default {
    name: "MessagesRendererComponent",
    components: {
        OrderPage
    },

    props: {
        messageNumber: {
            type: Number,
            required: false,
        }
    },

    beforeMount() {
        this.$store.commit('hideLoader');
        this.setCurrentMessage()
    },

    data() {
        return {
            currentMessage: null,
            editMode: false,
        }
    },

    methods: {
        setCurrentMessage() {
            if (this.messageNumber) {
                this.currentMessage = this.getCurrentMessage(this.messageNumber);
            } else {
                this.$router.push({ name: 'Quote', params: {messageNumber: (parseInt(1))} });
            }
        },
        getCurrentMessage(messageNumber) {
            var objectFromState = this.$store.getters['messageOrder'](messageNumber);
            
            if (objectFromState) {
                this.editMode = true;
                return objectFromState;
            }

            return new Message(messageNumber);
        }
    }
}
</script>