<template>
  <div class="mini-cart">
    <div class="edge" :style="[getOrderInCart > 0 ? 'border-color: transparent green transparent transparent;' : 'border-color: transparent #37475b transparent transparent;']"></div>
    <div class="cart-content" :style= "[getOrderInCart > 0 ? 'background:green!important' : 'background:#37475b']">
      <div class="item-container">
        <router-link
            to="/checkout"
        ><span style="color:white">{{ getOrderInCart }}</span></router-link>
      </div>
      <div class="cart-name">
        <router-link
            to="/checkout"
        >Carrello</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goToCheckout",
  computed: {
    getOrderInCart() {
        return this.$store.getters['messagesOrder'].length;
    }
  }
};
</script>

<style>
.mini-cart {
    position: fixed;
    right: 16px;
    top:0;
    color: #fff;
    width: 165px;
    z-index: 10;
}

.mini-cart .edge {
    -webkit-transition: border-color 1s ease;
    -moz-transition: border-color 1s ease;
    -o-transition: border-color 1s ease;
    transition: border-color 1s ease;
}
.mini-cart .edge {
    float: left;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 40px 0;
}

.mini-cart .cart-content {
    -webkit-transition: background 1s ease;
    -moz-transition: background 1s ease;
    -o-transition: background 1s ease;
    transition: background 1s ease;
}
.mini-cart .cart-content {
    float: right;
    width: 150px;
    height: 40px;
    background: #37475b;
    text-transform: uppercase;
}

.mini-cart .cart-content .item-container {
    position: relative;
    top: -8px;
    padding-left: 4px;
    margin-right: 15px;
    line-height: 130%;
    width: 32px;
    height: 32px;
    display: inline-block;
    background:  url('~@/assets/img/icon-cart.svg');
    -webkit-background-size: cover;
    background-size: cover;
    text-align: center;
    font-size: 12px;
}

.item-container a span {
    color: #fff;
}

.mini-cart .cart-content .cart-name {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.cart-name a {
    color: #fff;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mini-cart {
    right: 0;
  }
}
</style>
