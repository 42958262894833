<!--
=========================================================
* Vue Material Dashboard 2 - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>

  <sidenav
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
    v-if="showSidenav"
  />
  <div class="container-loader" v-else-if="showLoader">
    <div class="container-loader-elements">
      <img class="loader-img" :src="require('@/assets/img/loader.gif')" />
      <br>
      <span class="loader-details">Sto analizzando la richiesta...</span>
    </div>
  </div>
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <navbar
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="showNavbar"
    />

    <router-view :key="$route.fullPath"/>
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import { mapMutations, mapState } from "vuex";

export default {
  created () {
  },
  name: "App",
  components: {
    Sidenav,
    Navbar,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize", "forceShowSidenav"]),
    isAffiliateUrl() {
      var method = window.location.pathname.replace(/^\/([^/]*).*$/, '$1'); // .host.split('.');
      return method != "preventivo" && method != "checkout"
    }
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showLoader",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
};
</script>


<style>
  .container-loader {
    width:100%;
    height:100vh;
    position: relative;
  }

  .container-loader-elements {
    width:250px;
    height:auto;
    position: absolute;
    top: 43%;
    left: -webkit-calc(50% - 125px);;
  }

  .loader-img {
    margin-left:75px;
    width: 100px;
    height: 100px;
  }

  .loader-details {
    width:100%;
    text-align: center;
    display:block
  }
</style>