<template>
    <div class="container-loader">
    <div class="container-loader-elements">
      <img class="loader-img" :src="require('@/assets/img/loader.gif')" />
      <br>
      <span class="loader-details">Sto analizzando la richiesta...</span>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "automaticLogin",

    props: {
        ak: {
            type: String,
            required: true,
        },
        rdr: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            isLoading: true
        }
    },

    async beforeMount() {
      await this.handleLogin();
    },
    methods: {
      async handleLogin() {
          try {
              await this.$store.dispatch('automaticLogin', this.ak);
              await this.$router.push(`/my_site/how_it_works/${this.rdr}`);
          } catch (error) {
              this.error = error;
              //TODO add toast
          } finally {
              this.isLoading = false;
          } 
      },
    },
  };
  </script>
  