<template>
  <div class="card card-body mx-3 mx-md-4 mt-n1">
    <div class="row gx-4">  
      <div class="row">
        <div class="mt-4 col-md-6">
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user.NOME }} {{ user.COGNOME }}</h5> 
              <p class="mb-0 font-weight-normal text-sm">{{ user.FORMAGIURIDICA }}</p>
              <span class="mb-0 font-weight-normal text-sm">{{ user.CFISC }}</span>
            </div>
          </div>
        </div>
        <div class="mt-4 col-md-6">
          <div class="col-auto my-auto">
            <div class="h-100">
              <span class="mb-1" v-if="user.NCIVICO">{{ user.INDIRIZZO }}, {{ user.NCIVICO }}</span><br>
              <span class="mb-1" v-if="user.PROV">{{ user.CAP }} {{ user.CITTA }}({{ user.PROV }})</span>
              <span class="mb-1">{{ user.NAZIONE }}</span>
            </div>
          </div>
        </div>
      </div>
      <form class="mt-5">
        <div class="row">
          <div class="col-md-6">
            <label for="person-name" class="form-label">FORMA GIURIDICA</label>
            <div class="input-group input-group-outline">
              <input v-model="user.FORMAGIURIDICA" class="form-control" id="person-name">
            </div>
          </div>
          <div class="col-md-6">
            <label for="person-surname" class="form-label">CODICE FISCALE</label>
            <div class="input-group input-group-outline">
              <input v-model="user.CFISC" class="form-control" id="person-surname">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="person-name" class="form-label">INDIRIZZO SEDE</label>
            <div class="input-group input-group-outline">
              <input v-model="user.INDIRIZZO" class="form-control" id="person-name">
            </div>
          </div>
          <div class="col-md-6">
            <label for="person-surname" class="form-label">CAP</label>
            <div class="input-group input-group-outline">
              <input v-model="user.CAP" class="form-control" id="person-surname">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="person-name" class="form-label">PROVINCIA</label>
            <div class="input-group input-group-outline">
              <input v-model="user.PROV" class="form-control" id="person-name">
            </div>
          </div>
          <div class="col-md-6">
            <label for="person-surname" class="form-label">STATO</label>
            <div class="input-group input-group-outline">
              <input v-model="user.NAZIONE" class="form-control" id="person-surname">
            </div>
          </div>
          <div class="mt-3">
            <button style="float:right" type="submit" class="btn btn-primary">Salva</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import repository from "@/api/repository"

export default {
  name: "BillingDatas",
  data() {
    return {
      user: {
        NOME: "",
        COGNOME: "",
        FORMAGIURIDICA: "",
        NAZIONE: "",
        PREFISSO: "",
        EMAIL: "",
        SETTORE: "",
        CFISC: "",
        INDIRIZZO: "",
        NCIVICO: "",
        CAP: "",
        CITTA: "",
        PROV: ""
      }
    }
  },
  async beforeMount() {
    var { data } = (await repository.getMyDatas());
    this.user = data;
  }
};
</script>
