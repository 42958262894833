<template>
  <div v-if="messages.length > 0">
    <MessagesOrderSummary
      :messages="messages"
      :isLoadingNewPrices="isLoadingNewPrices"
      @delete-confirmation="deleteConfirmation"
    />
  </div>
</template>

<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { calculatePrice } from "@/assets/js/utils.js";
import MessagesOrderSummary from "../../components/MessagesOrderSummary.vue";
export default {
  name: "messageSummary",

  components: {
    MessagesOrderSummary
  },

  data() {
    return {
      messages: [],
      isLoadingNewPrices: false
    };
  },
  beforeMount() {
    this.messages = this.$store.getters["messagesOrder"];
  },
  methods: {
    deleteConfirmation(messageNumber) {
      this.$swal({
        title: "Sicuro di voler cancellare il messaggio?",
        text: "Il messaggio e il suo contenuto andranno persi.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cancella!",
        cancelButtonText: "Chiudi",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteMessageOrder", messageNumber);
          this.messages = this.$store.getters["messagesOrder"];
          this.isLoadingNewPrices = true;

          for (const message of this.messages) {
              var data = await calculatePrice(message);
              message.realCost = data.real_cost;
              message.withoutDiscount = data.without_disocunt;
              await this.$store.dispatch('setMessageOrder', message);
          }

          this.messages = this.$store.getters["messagesOrder"];
          this.isLoadingNewPrices = false;
          this.$swal.fire(
            "Cancellato!",
            "Il messaggio è stato cancellato.",
            "success"
          );
        }
      });
    },
  },
};
</script>

<style>
.titoloRiepilogoMessaggio {
  font-size: 30px;
  color: #af0060;
  margin-top: 20px;
  line-height: 105%;
}

.titoloRiepilogoMessaggio .testoTitoloRiepilogoMessaggio {
  float: left;
  margin-top: 4px;
}

.titoloRiepilogoMessaggio .numeroTitoloRiepilogoMessaggio {
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: 15px;
  background: #af0060;
  color: #fff;
  padding-top: 5px;
}

#riepilogoMessaggio {
  margin-bottom: 10px;
}

#riepilogoMessaggio .testo {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

#riepilogoMessaggio .voceRiepilogo {
  width: 90%;
  height: auto !important;
  min-height: 30px;
  border-bottom: 1px solid rgb(230, 232, 234);
}

#riepilogoMessaggio .campo {
  float: left;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  text-align: left;
  color: #738ca7;
}

#riepilogoMessaggio .testoCampo {
  float: right;
  width: 70%;
  margin-top: 4px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

#riepilogoMessaggio .testoRiepilogoOpzioniLingua {
  float: left;
}

#riepilogoMessaggio .bandieraRiepilogoOpzioniLingua {
  float: left;
  margin: 5px 10px;
  height: 16.67px;
  margin-bottom: 5px;
  margin-left: 0;
}

.boxPrezzoRiepilogoCarrello {
  width: 245px;
  float: right;
}

#riepilogoPreventivoPrezzo .prezzo {
  /* float: left; */
  color: #738ca7;
  font-size: 12px;
  text-align: right;
  margin-top: 30px;
}

#riepilogoPreventivoPrezzo .prezzoEffettivo {
  float: right;
  padding-top: 20px;
}

.prezzoNonScontato {
  padding-right: 10px;
}

.priceNewStyle {
  font-weight: bolder;
  color: #475d74;
  font-size: 46px;
  line-height: 115%;
}

.priceNewStyle small {
  font-size: 46%;
}

.prezzoNonScontato .priceNewStyle {
  font-size: 25px;
}

#speakers-summary {
  width: 60%;
}

#speakers-summary td {
  line-height: normal;
  margin-top: 3px !important;
}

a.buttonModificaCancella:hover,
a.buttonModificaCancella:link {
  font-size: 15px;
  color: #3b7dc1;
}

.buttonModificaCancella.modifica {
  background: #fff url("~@/assets/img/icons/bluePencil.jpg") no-repeat 5px 7px;
}
a.buttonModificaCancella {
  float: right;
  width: 250px;
  height: 36px;
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  color: #3b7dc1;
  border: 1px solid #3b7dc1;
  padding: 6px 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
}

.prezzoNonScontato .priceNewStyle > small,
.prezzoNonScontato .priceNewStyle > span {
  text-decoration: line-through;
}

a.buttonModificaCancella:hover,
a.buttonModificaCancella:link {
  font-size: 15px;
  color: #3b7dc1;
}
.buttonModificaCancella.cancella {
  background: #fff url("~@/assets/img/icons/blueTrash.jpg") no-repeat 5px 7px;
}
a.buttonModificaCancella {
  float: right;
  width: 250px;
  height: 36px;
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  color: #3b7dc1;
  border: 1px solid #3b7dc1;
  padding: 6px 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
}
</style>
