<template>
    <div class="card card-body mx-3 mx-md-4 mt-n1">
      <div class="row gx-4">  
        <div class="col-auto my-auto">
          <div class="h-100">
            <h4 class="mb-1">AffiliationDatas</h4>
          </div>
        </div>
        <div class="mt-4">
            AffiliationDatas
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: "AffiliationDatas",
  };
</script>
  