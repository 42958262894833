import store from '@/store';
import repository from '../../api/repository';

export function getPositionMessage(number) {
    var positions = {
        1: "primo",
        2: "secondo",
        3: "terzo",
        4: "quarto",
        5: "quinto",
        6: "sesto",
        7: "settimo",
        8: "ottavo",
        9: "nono",
        10: "decimo",
        11: "undicesimo",
        12: "dodicesimo",
        13: "tredicesimo",
        14: "quattordicesimo",
        15: "quindicesimo",
        16: "sedicesimo",
        17: "diciassettesimo",
        18: "diciottesimo",
        19: "diciannovesimo",
        20: "ventesimo",
        21: "ventunesimo",
        22: "ventiduesimo",
        23: "ventireesimo",
        24: "ventiquattresimo",
        25: "venticinquesimo"
    }
    return positions[number] ?? "Indefinito";
}

export function getItalianLanguageTitle(lang, capital = false)
{
    var langs = {
        "it": "italiano",
        "fr": "francese",
        "en": "inglese",
        "es": "spagnolo",
        "de": "tedesco",
        "ru": "russo",
        "ja": "giapponese",
        "zc": "cinese",
        "ar": "arabo",
        "pt": "portoghese"
    };

    if (langs[lang]) {
        if (capital) {
            return (langs[lang])[0].toUpperCase() + (langs[lang]).slice(1);
        }
        return langs[lang];
    }

    return "-";
}

export function getTotalMessages() {
    return store.getters['messagesOrder']?.length;
}

export function getMessageModel(messageModel) {
    var models = {
        "welcome": "Salve! Grazie per aver chiamato la \"XYZ\"",
        "privacy": "Benvenuto nel servizio clienti XYZ. La informiamo che XYZ tratta i dati raccolti durante questa chiamata in qualità di titolare nel rispetto del Regolamento Europeo in materia di protezione dei dati personali n. 679 del 2016. Se desidera ascoltare l’informativa privacy relativa al trattamento dei dati personali digiti il tasto 1. Altrimenti attenda in linea.",
        "wait": "La preghiamo di rimanere in linea, il primo operatore libero sarà presto a sua disposizione.  Nel frattempo le ricordiamo ... (sito, promo, email, consigli, ogni altra info)",
        "closed_offices": "Salve! Grazie per aver chiamato la \"XYZ\". I nostri uffici sono chiusi. Siamo aperti dal lunedì al venerdì dalle 8.30 alle 19.30. Se lo desidera, può  inviare una mail a info@xyz.com. Grazie e a presto!",
        "busy_lines": "I nostri operatori sono ancora tutti occupati, pertanto, per non farle perdere troppo tempo in attesa, se ci ha chiamato da un’utenza identificabile, sará ricontattato dal primo operatore libero. Grazie per aver chiamato e a più tardi!",
        "closed_holidays": "Salve! Gli uffici commerciali della \"XYZ\" rimangono chiusi per ferie da lunedì 10 Agosto a domenica 24 Agosto. Può inviare una email all'indirizzo info@xyz.com. Grazie per aver chiamato, le auguriamo buone vacanze!",
        "tree": "Prema 1 per parlare con l'ufficio commerciale, 2 per parlare con il magazzino, 3 per parlare con l'amministrazione. Altrimenti rimanga in attesa per parlare con il primo operatore disponibile. Grazie.",
        "personal": "Benvenuto! Questa è la segreteria telefonica del signor Carlo Rossi, al momento non posso rispondere, lasci un messaggio dopo il segnale acustico e sarà richiamato al più presto....",
        "covid": "Salve! Grazie per aver chiamato la XYZ srl. In ottemperanza all'ordinanza emessa in merito alle misure urgenti da contenimento per \"Corona Virus\" i nostri uffici rimarranno chiusi fino a data da destinarsi. E' attivo il servizio di supporto tecnico tramite smart working, inviando una mail a: xyz@xyz.it oppure chiamando il numero 328-1234567. Grazie a presto."
    }
    return models[messageModel] ?? null;
}

export async function getLowestPrice(forMessageN, affiliateId) {
    var { data } = await repository.getLowestPrice(forMessageN, affiliateId);
    return data
}

export function getActualDiscount() {
    return (getTotalMessages() ?? 0) <= 1 ? 15 : 30;
}

export async function calculatePrice(message) {
    const languageMap = {};

    languageMap["linguasito"] = "italiano";
    languageMap["linguaSitoSigla"] = "it";
    languageMap["linguaMessaggioOriginale"] = message.identifiedLanguage; // Change to iso
    languageMap["messaggioNumero"] = message.messageNumber;
    languageMap["totaleMessaggioCarello"] = message.messageNumber;
    languageMap["voce"] = message.sex;
    languageMap["numberoCaratteriPrimoMessaggio"] = 6;

    var {data} = await repository.getPricesText({ options: languageMap, messages: message.messagesByLanguages, affiliateId: store.getters['affiliateCib'] });
    return data;
}

export function isOnlyIdMusicalBase(musicalBaseType) {
    return ['ClassicMusic', 'ModernMusic', 'ChristmasMusic'].includes(musicalBaseType);
}