<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="dashboard"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="true"
          collapseRef="my_datas"
          navText="I tuoi dati"
        >
          <template v-slot:list id="my_datas" style="width:100%">
            <ul class="navbar-nav">
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_datas/profile"
                  navText="Dati profilo"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">person</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_datas/billing_datas"
                  navText="Dati fatturazione"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">account_balance</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_datas/affiliation_datas"
                  navText="Dati affiliazione"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">route</i>
                  </template>
                </sidenav-collapse>
              </li>
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->

      <li class="nav-item">
            <ul class="navbar-nav">
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_site/how_it_works"
                  navText="Come funziona?"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">help_outline</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_site/configuration"
                  navText="Configurazione"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">edit</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_site/quotes_to_validate"
                  navText="Preventivi da convalidare"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">schedule_send</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/my_site/validated_quotes"
                  navText="Preventivi convalidati"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">task_alt</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:isExternalLink="true"
                  v-bind:collapse="false"
                  :collapseRef="getMyMessagesSite()"
                  navText="Vai al mio sito di messaggi"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">exit_to_app</i>
                  </template>
                </sidenav-collapse>
              </li>
              
            </ul>
      </li>


      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="true"
          collapseRef="word_of_mouth"
          navText="Passaparola e guadagna"
        >
          <template v-slot:list id="word_of_mouth" style="width:100%">
            <ul class="navbar-nav">
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/word_of_mouth/how_it_works_w"
                  navText="Come funziona"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">help_outline</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/word_of_mouth/promotion_tools"
                  navText="Strumenti di promozione"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">campaign</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/word_of_mouth/my_clients"
                  navText="I tuoi clienti"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">people</i>
                  </template>
                </sidenav-collapse>
              </li>
              <li class="nav-item">
                <sidenav-collapse
                  url="#"
                  :aria-controls="''"
                  v-bind:collapse="false"
                  collapseRef="/word_of_mouth/accrued_commissions"
                  navText="Commisioni maturate"
                >
                  <template v-slot:icon>
                    <i class="material-icons-round opacity-10 fs-5">savings</i>
                  </template>
                </sidenav-collapse>
              </li>
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavCollapse
  },
  methods: {
    getMyMessagesSite() {
      return `http${process.env.NODE_ENV == 'development' ? '' : 's'}://${this.$store.getters['cid']}.${process.env.VUE_APP_MESSAGES_URL}`;
    }
  }
};
</script>


<style>
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link:hover {
  background-color:  rgba(199, 199, 199, 0.2)!important;
}

.navbar-vertical .nav-item:hover .nav-link {
    background-color: transparent!important;
}
</style>