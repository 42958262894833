<template>
    <div
      tabindex="-1"
      role="dialog"
      class="modal fade show d-block"
      :class="{ 'd-none': !showModalData, 'd-block': showModalData }"
      style=""
    >
      <div class="modal-dialog" style="width:1000px;">
        <div class="modal-content" style="width: 680px;">
          <div class="modal-header justify-content-center">
          <!-- <button type="button" data-dismiss="modal" class="close">
              x
          </button> -->
          <h5 class="title title-up"><slot name="header"></slot></h5>
          </div>
          <div class="modal-body">
            <span><slot name="body"></slot></span>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="closeModal()"
              class="btn btn-link btn-neutral"
            >
              <!---->Chiudere<!---->
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      props: {
        showModal: {
          type: Boolean,
          required: true
        }
      },
      data() {
        return {
          showModalData: this.showModal
        }
      },
  
      methods: {
        closeModal() {
          this.showModalData = false;
          this.$emit('close');
        }
      },
  
      watch: {
        showModal(trigger) {
          if (!trigger) {
            this.closeModal();
          } else {
            this.showModalData = trigger;
          }
        }
      }
    }
  </script>
  
  <style>
  .modal.show {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
  }
  
  .modal-header {
      border-bottom: 1px solid #ddd;
      padding: 20px;
      text-align: center;
      display: block!important;
  }
  </style>
  