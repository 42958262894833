<template>
  <Modal 
    :show-modal="openModalData"
  >
    <template v-slot:header>
        Scegli la base
    </template>
    <template v-slot:body>
      <Radiobutton
        :el="complexContentList ?? []"
        @update="save"
      />
    </template>
  </Modal>
</template>

<script>
  import AudioPlayer from '../../components/AudioPlayer.vue';
  import Modal from '../../components/Modal.vue';
  import Radiobutton from '../../components/Radiobutton.vue';
  import Base from './base.vue';
  import { markRaw } from 'vue'

  export default {
    extends: Base,
    components: {
        Modal,
        Radiobutton
    },

    data() {
      return {
        complexContentList: []
      }
    },

    watch: {
      contentList(newV) {
        var tmpOrder = [];
        newV.forEach(function(content) {
            content.id = `musical_base_${content.id}`
            content.component = markRaw(AudioPlayer);
            content.componentProps = { id: `audio_${content.id}`, preview: content.link };
            tmpOrder.push(content);
        });
        this.complexContentList = tmpOrder;
      }
    }
  }
</script>

