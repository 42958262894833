<template>
  <div class="card card-body mx-3 mx-md-4 mt-n1">
      <div class="row gx-4">  
        <div class="col-auto my-auto">
          <div class="h-100">
            <h4 class="mb-1">Passaparola e guadagna</h4>
          </div>
        </div>
        <div class="mt-4">
          <nav class="accordion arrows">
    <input type="radio" name="accordion" id="cb1" />
    <section class="box">
      <label class="box-title" for="cb1">Modulo di richiesta preventivo cartaceo per i tuoi clienti</label>
      <label class="box-close" for="acc-close"></label>
      <div class="box-content">Stampa questo file pdf e consegnalo a tutti i tuoi clienti quando ti rechi ad installare un impianto telefonico nuovo.
        I clienti "vecchio stampo" potrebbero preferire questo modulo cartaceo piuttosto che internet.
        Il modulo potrà essere inviato a te via email, opportunamente compilato a penna dal cliente in ogni suo intuitivo campo, così che tu possa prendere nota della richiesta del cliente e rispondergli con un preventivo.
      </div>
    </section>
    <input type="radio" name="accordion" id="cb2" />
    <section class="box">
      <label class="box-title" for="cb2">Codice sconto identificativo</label>
      <label class="box-close" for="acc-close"></label>
      <div class="box-content">Regala ai tuoi clienti questo codice sconto: <b>DAVIDEGAGLIARDI1000</b>
        <br>Sconto utilizzabile nel nostro sito <b>www.TelephoneVox.com</b>
      </div>
    </section>
    <input type="radio" name="accordion" id="cb3" />
    <section class="box">
      <label class="box-title" for="cb3">Indirizzo internet identificativo</label>
      <label class="box-close" for="acc-close"></label>
      <div class="box-content">Segnala ai tuoi clienti il nostro servizio mandandoli a questo indirizzo specifico:<br>
        <b style="font-size:18px">davidegagliardi.whitelabel.com</b><br>
        In questo modo non serve specificare anche il codice sconto.
      </div>
    </section>
    <input type="radio" name="accordion" id="cb4" />
    <section class="box">
      <label class="box-title" for="cb4">Link testuale da pubblicare nel tuo sito</label>
      <label class="box-close" for="acc-close"></label>
      <div class="box-content">
        Personalizza la tua segreteria/centralino con una voce professionale<br>
        <b style="font-size:18px">www.TelephoneVox.com</b><br>
        C'è uno sconto se accedi da qui.
      </div>
    </section>
    <input type="radio" name="accordion" id="cb5" />
    <section class="box">
      <label class="box-title" for="cb5">Banner grafici da pubblicare nel tuo sito</label>
      <label class="box-close" for="acc-close"></label>
      <div class="box-content">Aggiungere tutti i banners</div>
    </section>

    <input type="radio" name="accordion" id="acc-close" />
  </nav>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "PromotionTools",
};
</script>

<style>
.accordion {
  margin: auto;
  width: 100%;
}
.accordion input {
  display: none;
}
.box {
  position: relative;
  background: white;
    height: 64px;
    transition: all .15s ease-in-out;
}
.box::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    box-shadow: 0 -1px 0 #e5e5e5,0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
}
header.box {
  background: #00BCD4;
  z-index: 100;
  cursor: initial;
  box-shadow: 0 -1px 0 #e5e5e5,0 0 2px -2px rgba(0,0,0,.12),0 2px 4px -4px rgba(0,0,0,.24);
}
header .box-title {
  margin: 0;
  font-weight: normal;
  font-size: 16pt;
  color: white;
  cursor: initial;
}
.box-title {
  width: calc(100% - 40px);
  height: 64px;
  line-height: 64px;
  padding: 0 20px;
  display: inline-block;
  cursor: pointer;
  color:rgb(74, 145, 204);
  font-weight: bold;
  -webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
}
.box-content {
  width: calc(100% - 40px);
  padding: 30px 20px;
  font-size: 11pt;
  color: rgba(0,0,0,.54);
  display: none;
}
.box-close {
  position: absolute;
  height: 64px;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  font-weight: bold;
}
input:checked + .box {
  height: auto;
  margin: 16px 0;
    box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
}
input:checked + .box .box-title {
  border-bottom: 1px solid rgba(0,0,0,.18);
  color:rgb(190, 25, 115);
}
input:checked + .box .box-content,
input:checked + .box .box-close {
  display: inline-block;
}
.arrows section .box-title {
  padding-left: 44px;
  width: calc(100% - 64px);
}
.arrows section .box-title:before {
  position: absolute;
  display: block;
  content: '\203a';
  font-size: 18pt;
  left: 20px;
  top: -2px;
  transition: transform .15s ease-in-out;
  color: rgba(0,0,0,.54);
}
input:checked + section.box .box-title:before {
  transform: rotate(90deg);
}
</style>
