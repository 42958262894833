<template>
    <audio
    :ref="`player-${id}`"
    controls
    controlsList="nodownload"
    :src="preview"
    type="audio/mpeg"
    @play="played(id)"
    style="height:30px!important;float:right"
></audio>
</template>

<script>
export default {
    name: "AudioPlayer",
    props: {
        id: {
            type: [Number, String],
            default: null,
            required: true,
        },
        preview: {
            Type: String,
            default: null,
            required: true,
        },
        forceStop: {
            Type: Boolean,
            default: false,
            required: false
        }
    },

    methods: {
        async played()
        {
            // this.$emit("played", this.id);
            await this.$store.dispatch('setPlayedId', this.id);
        }
    },

    watch: {
        '$store.state.playedBase': function() {
            if (this.$store.state.playedBase != this.id) {
                let player = this.$refs[`player-${this.id}`];
                player.pause();
            };
        }
    }
}

</script>