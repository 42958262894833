<template>
    <button 
        class="btn mx-1 mb-0 bg-gradient-success btn-md null my-4 mb-2 wa" 
        @click="clicked()" 
        style="line-height: 1.3333333;padding-top: 15px;font-size: 18px;height: 100px;background-image: linear-gradient(195deg, rgb(74, 146, 223), rgb(47, 104, 165));">
        <strong>AGGIUNGI UN ALTRO MESSAGGIO
            <br>A PARTIRE DA {{ startFrom }}€<br>
            (SCONTO DEL {{ actualDiscount }}%)
        </strong>
    </button>
</template>

<script>
import { getLowestPrice, getActualDiscount, getTotalMessages } from "@/assets/js/utils.js";

export default {
    name: "addMessageButton",

    props: {
        forMessageN: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            startFrom: "-",
            actualDiscount: "-"
        }
    },

    async beforeCreate() {
        this.startFrom = await getLowestPrice(this.forMessageN, this.$store.getters['affiliateCib']);
        this.actualDiscount = await getActualDiscount();
    },

    methods: {
        clicked() {
            this.$emit("click")
        }
    },

    watch: {
        '$store.state.messagesOrder': async function() {
            var tmpMessageFor = this.forMessageN;
            var totalMessages = getTotalMessages();

            if (tmpMessageFor > totalMessages + 1) {
                tmpMessageFor = totalMessages + 1;
            }

            this.startFrom = await getLowestPrice(tmpMessageFor, this.$store.getters['affiliateCib']);
            this.actualDiscount = await getActualDiscount();
        }
    }
    
}
</script>

<style>
.wa {
    width:400px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .wa {
    width: 90%;
  }
}
</style>