import api from "./api";

export default {
    /**
     * REGION GET START    
     * */
    getMyDatas() {
        return api.get(`/api/mydatas`);
    },

    getCosts() {
        return api.get(`/client/getCosts`)
    },

    getOrderPageElements() {
        return api.get(`/client/getOrderPageElements`)
    },

    getMyClients() {
        return api.get(`/api/clients`)
    },

    getMyQuotes() {
        return api.get('/api/quotes')
    },

    getMyValidatedQuotes() {
        return api.get(`/api/validatedQuotes`)
    },

    getConfiguration() {
        return api.get(`/api/configuration`);
    },

    getLanguageIdentified(text) {
        return api.get('/client/getLanguage?text=' + text)
    },

    getTranslations(text, source, languagesToTranslateTo) {
        return api.get('/client/getTranslations', {
            params: {
                text: text,
                source: source,
                toLanguages: languagesToTranslateTo.reduce((f, s) => `${f},${s}`)
            }
        })
    },

    getPricesText(complexObject) { 
        return api.post('/client/getPricesByTexts', complexObject);
    },

    getModalContent(type) {
        return api.get(`/client/getModalContent?type=${type}`)
    },

    getLowestPrice(messageNumber, affiliateId) {
        return api.get(`/client/getLowestPrice?numberMessages=${messageNumber}&affiliateId=${affiliateId}`)
    },

    getAffiliateInfos(id) {
        return api.get(`/client/getAffiliateInformations?affiliateId=${id}`)
    },

    checkAffiliateCid(cid) {
        return api.get(`/client/checkAffiliateCid?cid=${cid}`)
    },

    getVoicesBySpeakerId() {
        return api.get(`/client/getVoicesBySpeakerId`)
    },
    /**
     * REGION GET END    
     * */


    /**
     * REGION POST START    
     * */
    login(params) {
        return api.get(`/api/login`, {
            params: params
          });
    },

    automaticLogin(params) {
        return api.get(`/api/automaticAuthenticate?ak=${params}`);
    },

    validateOrder(id) {
        return api.post(`/api/validate`, {
            id: id
          });
    },

    createClient(params) {
        return api.post(`${process.env.BASE_URL}/api/clients`, params);
    },

    createTelephoneNumber(params) {
        return api.post(`${process.env.BASE_URL}/api/telephones`, params);
    },

    saveConfiguration(params) {
        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        }
        return api.post(`/api/configuration`, params, config);
    },
    createReport(params) {
        return api.post(`${process.env.BASE_URL}/api/createReport`, params, {responseType: 'arraybuffer'});
    },

    placeOrder(order) {
        return api.post(`/client/placeOrder`, order);
    },
    /**
     * REGION POST END    
     * */

    /**
     * REGION DELETE START    
     * */
    logout() {
        return api.delete(`/api/logout`);
    },

    deleteUser(id) {
        return api.delete(`${process.env.BASE_URL}/api/clients/${id}`)
    },

    deleteTelephone(id) {
        return api.delete(`${process.env.BASE_URL}/api/telephones/${id}`)
    },

    /**
     * REGION DELETE END    
     * */


    /**
     * REGIONE PATCH START
     */
    updateClient(id, params) {
        // console.log(id, params)
        return api.put(`${process.env.BASE_URL}/api/clients/${id}`, params);
    },

    updateTelephoneNumber(id, params) {
        return api.put(`${process.env.BASE_URL}/api/telephones/${id}`, params)
    }

    /**
     * REGIONE PATCH END
     */


}