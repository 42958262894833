<template>
    <div
    class="row"
    v-for="message in messages"
    v-bind:key="message.messageNumber"
    style="width: 70%; margin: 0 auto"
  >
    <div class="col-lg-8 col-md-8 col-sm-7 col-xs-12">
      <div class="titoloRiepilogoMessaggio">
        <div class="testoTitoloRiepilogoMessaggio">Messaggio</div>
        <div class="numeroTitoloRiepilogoMessaggio">
          {{ message.messageNumber }}
        </div>
        <br class="clearer" />
      </div>
      <div id="riepilogoMessaggio">
        <div class="testo">
          <div
            v-for="language in Object.keys(
              sortByKeyAlphabetically(message.messagesByLanguages)
            )"
            v-bind:key="language"
          >
            <b>Testo in {{ getLanguageTitle(language) }}</b>
            <br />
            {{ message.messagesByLanguages[language] }}
            <br />
            <br />
          </div>
        </div>
        <table id="speakers-summary" style="width: 60%">
          <tr>
            <td style="width: 50%">OPZIONI SPEAKER</td>
            <td>{{ message.sex }}</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ message.type }}</td>
          </tr>
          <tr
            v-for="language in Object.keys(
              sortByKeyAlphabetically(message.speakersByLanguages)
            )"
            v-bind:key="language"
          >
            <td></td>
            <td style="display: flex; width: 100%; margin-top: 15px">
              <img
                width="25"
                class="bandieraRiepilogoOpzioniLingua"
                alt=""
                :src="`/flags/${language}.svg`"
              />
              <div class="">
                Speaker&nbsp;{{ message.speakersByLanguages[language] }}-{{ speakerNumVoiceBySpeakerId[message.speakersByLanguages[language]] }}
              </div>
            </td>
          </tr>
        </table>
        <div v-if="message.hasMusicalBase">
          <hr />
          <table id="other-options-summary" style="width: 100%">
            <tr>
              <td style="width: 30%">ALTRE OPZIONI</td>
              <td> {{ getReadableMusicalBase(message.durationMusicalBase, message.musicalBase) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
      <div class="boxPrezzoRiepilogoCarrello">
        <div id="riepilogoPreventivoPrezzo">
          <div class="prezzo">PREZZO DI LISTINO<br />IVA ESCLUSA</div>
          <div v-if="!isLoadingNewPrices" class="prezzoEffettivo">
            <span class="prezzoNonScontato">
              <span class="priceNewStyle" v-if="message.messageNumber > 1">
                <span class="">{{
                  message.withoutDiscount?.toString().split(".")[0]
                }}</span
                ><small
                  ><span class=""
                    >,{{
                      message.withoutDiscount
                        ?.toString()
                        .split(".")[1]
                        .slice(0, 2)
                    }}</span
                  >
                  €</small
                >
              </span>
            </span>
            <span class="priceNewStyle">
              <span class="">{{
                message.realCost?.toString().split(".")[0]
              }}</span>
              <small
                ><span class=""
                  >,{{
                    message.realCost?.toString().split(".")[1].slice(0, 2)
                  }}</span
                >
                €</small
              >
            </span>
          </div>
          <div v-else class="mt-3" style="display: flex;width:100%;justify-content: end;">
            <Skeletor width="80%" height="50px" class="mt-3;"/>
          </div>
          <input type="hidden" id="imponibileMessaggio-1" value="153.23" />
          <br class="clearer" />
        </div>
        <div class="prezzoMsgIcone" v-if="!hideButtons">
          <a
            class="buttonModificaCancella modifica"
            :onclick="
              () =>
                this.$router.push({
                  name: 'Quote',
                  params: { messageNumber: parseInt(message.messageNumber) },
                })
            "
            title="MODIFICA"
          >
            MODIFICA MESSAGGIO
          </a>
          <a
            class="buttonModificaCancella cancella"
            :onclick="() => deleteConfirmation(message.messageNumber)"
            title="CANCELLA"
          >
            CANCELLA MESSAGGIO
          </a>
          <br class="clearer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";
import { getItalianLanguageTitle, isOnlyIdMusicalBase }  from "@/assets/js/utils.js"
import repository from "../api/repository";

export default {
    name: "MessagesOrderSummary",
    props: {
        messages: {
            Type: Array,
            required: true
        },
        isLoadingNewPrices: {
            Type: Boolean,
            required: false,
            default: false
        },
        hideButtons: {
            Type: Boolean,
            required: false,
            default: false
        }
    },

    components: {
        Skeletor,
    },

    data() {
      return {
        speakerNumVoiceBySpeakerId: []
      }
    },

    async beforeMount() {
      await this.getVoices();
    },

    methods: {
        sortByKeyAlphabetically(objectToSort) {
          return Object.keys(objectToSort)
            .sort()
            .reduce((objEntries, key) => {
            objEntries[key] = objectToSort[key];
            return objEntries;
            }, {});
        },       
        async getVoices() {
          var {data} = await repository.getVoicesBySpeakerId();
          this.speakerNumVoiceBySpeakerId = data;
        },
        deleteConfirmation(e) {
            this.$emit("delete-confirmation", e)
        },
        getLanguageTitle(lang, capital = false) {
          return getItalianLanguageTitle(lang, capital);
        },
        getReadableMusicalBase(duration, musicalBaseType) {
          if (isOnlyIdMusicalBase(musicalBaseType.type)) {
            return `Base n° ${musicalBaseType.value} che prosegue oltre il parlato per ${duration} secondi.`
          } else {
            return `${Object?.values(musicalBaseType.value)?.join("/")}, base che prosegue oltre il parlato per ${duration} secondi.`
          }
        }
    }
}
</script>