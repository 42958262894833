<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :color="color" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <span @click="goToConfiguration" v-if="!$store.getters['hasConfiguration']" style="cursor: pointer; display:flex;background-color: yellow;padding: 5px 5px 5px 5px; border-radius: 8px;" class="mx-3 pulse"><i class="material-icons-round" style="padding-top: 3px;">warning</i><span>IL TUO SITO E' INATTIVO: CONFIGURAZIONE MANCANTE</span></span>
        <span style="background-color: lightgreen;padding: 5px 5px 5px 5px; border-radius: 8px;" class="mx-3">{{ $store.getters['staticHeaderInfo'] }}</span>

        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color ? color : 'text-body', showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="material-icons cursor-pointer"> account_circle </i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2" style="display: flex;flex-direction: row;align-items: center;">
                <i class="material-icons cursor-pointer"> logout </i><span v-on:click="handleLogout()" style="font-size:16px;">&nbsp;&nbsp;Esci</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
  },
  methods: {
    toggleSidebar() {
      this.navbarMinimize();
    },

    async handleLogout() {
      try {
        await this.$store.dispatch('logout');
        await this.$router.replace({name: 'SignIn'})
      } catch (error) {
        this.error = error;
      } finally {
        // this.loading = false;
      }
    },

    goToConfiguration() {
      this.$router.push('/my_site/configuration')
    }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.pulse {
  display: block;
  border-radius: 8px;
  background: #cca92c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
