import store from '../../../store';
import repository from '../../../api/repository';

export default async (to, from, next) => {

    var urlParts = window.location.host.split('.');

    if (urlParts.length != 3) {
        next({ name: 'NotFound' });
    }

    if (store.getters['affiliateCib'] == urlParts[0]) {
        next();
    }

    store.commit('showLoader');
    try {
        var { data } = await repository.checkAffiliateCid(urlParts[0]);
        if (data)  {
            await store.dispatch("setAffiliateChecked", data);
            next();
        }
    } catch {
        next({ name: 'NotFound' });
        store.commit('hideLoader');
    }
}