<template>
<div class="cc-selector" style="margin-top:20px;">
    <div v-if="!inHalf">
        <div 
            v-for="element in el"
            v-bind:key="`radio_${element.id}`"
            class="radio-container"
            :style="{ 'border-bottom': showHr  ? '' : '0px solid #ffff', 'margin-bottom': showHr  ? '' : '-15px' }"
        >
                <div style="width:30px;">
                    <input :id="`radio_${element.id}`" type="radio" :value="`radio_${element.id}`" v-model="pickedR" />
                    <label :class="[pickedR == `radio_${element.id}`? 'checked' : 'unchecked', 'drinkcard-cc']" :for="`radio_${element.id}`"></label>
                </div>
                <div style="margin-left:5px;">
                    {{ element.label }}
                    <component 
                        v-if="element.component && element.componentProps"
                    :is="element.component"
                    v-bind="element.componentProps"
                    @updated="updateComponent"
                    />
                    <span 
                        v-if="element.suffix" style="margin-left: 3px;"
                        v-show="showSuffixOnClick ? pickedR == `radio_${element.id}` : (1 == 1)"
                        v-html="element.suffix"
                        @click="updateSuffix"
                    >
                    </span>
                </div>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-md-6 bordered-column">
            <div 
                v-for="element in el.slice(0, getHalf)"
                v-bind:key="`radio_${element.id}`"
                class="radio-container row"
                style="padding-bottom: 10px;padding-top:10px;"
                :style="{ 'border-bottom': showHr  ? '' : 'none!important;', 'margin-bottom': '5px;'}"
            >

                <div class="col-md-6" style="display:flex">
                    <input :id="`radio_${element.id}`" type="radio" :value="`radio_${element.id}`" v-model="pickedR" />
                    <label :class="[pickedR == `radio_${element.id}`? 'checked' : 'unchecked', 'drinkcard-cc']" :for="`radio_${element.id}`"></label>
                    <div style="margin-left:10px;display: inline-block;vertical-align: middle;line-height: normal;">
                        {{ element.label }}
                    </div>
                </div>
                <div v-if="element.component && element.componentProps" class="col-md-6">
                    <component 
                        :is="element.component"
                        v-bind="element.componentProps"
                        @updated="updateComponent"
                    />
                </div>
                <div v-if="element.suffix" style="flex-grow: 1;">
                    <div 
                        v-show="showSuffixOnClick ? pickedR == `radio_${element.id}` : pickedR != `radio_${element.id}`"
                        v-html="element.suffix"
                        @click="updateSuffix"
                    >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div 
                v-for="element in el.slice(getHalf)"
                v-bind:key="`radio_${element.id}`"
                class="radio-container row"
                style="padding-bottom: 10px;padding-top:10px;"
            >
                <div class="col-md-6" style="display:flex">
                    <input :id="`radio_${element.id}`" type="radio" :value="`radio_${element.id}`" v-model="pickedR" />
                    <label :class="[pickedR == `radio_${element.id}`? 'checked' : 'unchecked', 'drinkcard-cc']" :for="`radio_${element.id}`"></label>
                    <div style="margin-left:10px;display: inline-block;vertical-align: middle;line-height: normal;">
                        {{ element.label }}
                    </div>
                </div>
                <div v-if="element.component && element.componentProps" class="col-md-6">
                    <component 
                        :is="element.component"
                        v-bind="element.componentProps"
                        @updated="updateComponent"
                    />
                </div>
                <div v-if="element.suffix" style="flex-grow: 1;">
                    <div 
                        v-show="showSuffixOnClick ? (pickedR == `radio_${element.id}`) : (pickedR != `radio_${element.id}`)"
                        v-html="element.suffix"
                        @click="updateSuffix"
                    >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "RadioComponent",

    props: {
        el: {
            type: Array,
            required: true
        },
        inHalf: {
            type: Boolean,
            required: false,
            default: false
        },
        showSuffixOnClick: {
            type: Boolean,
            required: false
        },
        showHr: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            pickedR: null
        }
    },

    beforeMount() {
        this.setId();
    },

    computed: {
        getHalf() {
            return Math.ceil(this.el.length / 2)
        }
    },

    methods: {
        setId() {
            let preselected = this.el.find(o => o.selected == true);
            if (preselected) {
                this.pickedR = `radio_${preselected.id}`;
            }
        },
        updateSuffix(e) {
            let clickedElId = e.target.id
            if (clickedElId) {
                this.$emit("update-suffix", clickedElId);
            }
        },
        updateComponent(e) {
            this.$emit("update-component", e);
        }
    },

    watch: {
        pickedR(newValue) {
            this.$emit("update", newValue.replace("radio_", ""));
        },
        el () {
            this.setId();
        }
    }
}
</script>

<style>
.cc-selector input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}
.unchecked{background-image:url(https://www.telephonevox.com/vendor/telephonevox-tp/img/form-elements/radio-0.svg);}
.checked{background-image:url(https://www.telephonevox.com/vendor/telephonevox-tp/img/form-elements/radio-1.svg);}

.cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector input:checked +.drinkcard-cc{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
.drinkcard-cc{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    width: 19px;
    height: 19px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
}

/* Extras */
a:visited{color:#888}
a{color:#444;text-decoration:none;}
p{margin-bottom:.3em;}

.radio-container {
    display: flex;
    width: 100%;
    margin-top:15px;
}

.cc-selector .radio-container:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.label-row span {
    font-size: 16px;
}
.background-blue {
    background-color: #3B7DC1;
    color: #fff;
    padding: 5px;
}
</style>