<template>
    <div>
        <Radiobutton
            :el="listRadioButtons ?? []"
            :inHalf="true"
            :showSuffixOnClick="true"
            @update="updateBaseType"
            @update-suffix="modalHasBeenOpen"
        />
        <component 
            :is="currentComponent"
            v-bind="currentComponentProps"
            :openModal="openModal"
            :typeModal="typeModal"
            :model="baseData"
            @close="openModal = false"
            @filled="saveMusicalBase"
        />
    </div>
</template>

<script>
import Radiobutton from './Radiobutton.vue';
import * as Modals from '@/views/musicalBasesModals/loader.js';

export default {
    name: "MusicalBases",

    components: {
        Radiobutton,
    },

    props: {
        base: {
            type: [Object, Number],
            required: false
        }
    },

    created() {
        if (this.base) {
            this.typeModal = this.base.type;
            this.saveMusicalBase(this.base.value);
        }
    },

    data() {
        return {
            currentComponent: null,
            currentComponentProps: null,
            openModal: false,
            typeModal: "",
            baseData: null,
            listRadioButtons: [
                {
                    id: "classic_music",
                    idBtn: "ClassicMusic",
                    label: "Musica classica",
                    selected: this.getIsSelected("ClassicMusic"),
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="ClassicMusic">Scegli la base</button>'
                },
                {
                    id: "modern_music",
                    idBtn: "ModernMusic",
                    label: "Musica moderna",
                    selected: this.getIsSelected("ModernMusic"),
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="ModernMusic">Scegli la base</button>',
                },
                {
                    id: "christmas_music",
                    idBtn: "ChristmasMusic",
                    label: "Musica natalizia",
                    selected: this.getIsSelected("ChristmasMusic"),
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="ChristmasMusic">Scegli la base</button>',
                },
                {
                    id: "author_and_title",
                    idBtn: "AuthorAndTitle",
                    label: "Indico autore e titolo",
                    selected: this.getIsSelected("AuthorAndTitle"),
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="AuthorAndTitle">Indica la base</button>'
                },
                {
                    id: "link",
                    idBtn: "Link",
                    label: "Indico link (youtube, vimeo, etc)",
                    selected: this.getIsSelected("Link"),
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="Link">Indica il link</button>'
                },
                /* {
                    id: "give_musical_base",
                    label: "Fornisco la mia base musicale",
                    suffix: '<button class="btn btn-info btn-sm" style="float:right" id="GiveMusicalBase">Fornisci la base</button>'
                } */
            ]
        }
    },

    methods: {
        saveMusicalBase(valueToSave) {
            this.$emit("save-musical-base", {
                type: this.typeModal,
                value: valueToSave
            });
        },
        updateBaseType(e)
        {
            // TODO use more elegant way, this is bleah
            var obj = this.listRadioButtons.find(function(el) {
                return el.id == e;
            })
            
            if (obj && obj.idBtn != this.base?.type) {
                this.$emit("save-musical-base", {
                    type: obj.idBtn,
                    value: ""
                });
            }
        },
        modalHasBeenOpen(kind)
        {
            this.currentComponent = (Modals[kind]);
            this.typeModal = kind;
            this.openModal = true;
        },
        getIsSelected(modalType)
        {
            if (this.base) {
                return modalType == this.base.type;
            }
            else if (modalType == "ClassicMusic") {
                return true;
            }
        }
    },

    watch: {
        base(newV) {
            this.baseData = newV;
        }
    }
}

</script>