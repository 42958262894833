<template>
  <section v-if="messageData" id="preventivo" style="background-color: white">
    <Header />
    <div class="container">
      <GoToCheckout style="margin-top:78px;"></GoToCheckout>
      <div class="row mt-5">
        <div class="col-sm-10 offset-sm-1 bc-right">
          <h3 class="color-accent">Voci e lingue</h3>
          <div class="form-group">
            <div id="sex">
              <h4 class="font-md-lg">Sesso dello speaker</h4>
              <div class="intro">Scegli il sesso dello speaker</div>
              <Radiobutton
                v-if="quoteBaseInfo"
                :el="quoteBaseInfo.sexes ?? []"
                @update="updateSex"
              />
            </div>
            <hr />
            <div id="language">
              <h4 class="font-md-lg">Lingua dello speaker</h4>
              <div class="intro">Usiamo speakers madrelingua</div>
              <Checkbox
                v-if="quoteBaseInfo"
                :el="quoteBaseInfo.languages ?? []"
                :inHalf="true"
                @update="updateLanguages"
              />
            </div>
            <hr />
            <!-- <div id="type">
              <h4 class="font-md-lg">Speaker robot o umano</h4>
              <div class="intro">
                Scegli la tipologia di voce che preferisci
              </div>
              <Radiobutton
                v-if="quoteBaseInfo"
                :el="quoteBaseInfo.types ?? []"
                @update="updateType"
              />
            </div> -->
            <div id="choose_speaker" class="mt-5">
              <SpeakerResolver
                v-if="quoteBaseInfo"
                :el="quoteBaseInfo.voices ?? []"
                :typeToShow="messageData.type"
                :sexToShow="messageData.sex"
                :languagesToShow="messageData.selectedLanguages"
                @speaker-updated="updateSpeakersByLanguage"
              />
            </div>
            <div id="texts" class="mt-5">
              <MessagesText
                v-if="quoteBaseInfo && messageData.sex"
                :selected-languages="messageData.selectedLanguages"
                :presetMessages="messageData.messagesByLanguages"
                :iF="messageData.identifiedLanguage"
                :sex="messageData.sex"
                :message-number="message.messageNumber"
                @messages-updated="updateMessagesInformations"
              />
            </div>            
            <hr />
            <h3 class="color-accent">Opzioni e dettagli</h3>
            <hr />
            <div id="musical_base" class="mb-5">
              <div id="choose_speaker">
                <h4 class="font-md-lg">Base musicale</h4>
                <div class="intro">
                  Vuoi abbinare una base musicale al tuo messaggio?
                </div>
                <MusicalBase
                  :hasMusicalBase="messageData.hasMusicalBase"
                  :durationMusicalBase="messageData.durationMusicalBase"
                  @update="updateMusicalBase"
                  @update-component="updateDurationMusicalBase"
                />
                <!-- <input style="width:50px;" type="number" v-model="durationMusicalBase" @change="updateDurationMusicalBase"> -->
                <MusicalBases
                    v-if="messageData.hasMusicalBase"
                    :base="messageData.musicalBase"
                    @save-musical-base="setMusicalBase"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="summary-band">
          <div class="row">
            <div class="col-md-9 col-sm-6">
              <div class="summary-description">
                <div class="font-lg">Totale da scontare</div>
                <div class="font-sm" style="letter-spacing: 1px">
                  (IVA esclusa)
                </div>
              </div>
              <br class="clearer" />
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="summary-price">
                <div class="finestraRisparmiaree"><!-- TODO to implement -->
                  <!-- <strong
                    >Prezzo "una tantum"! Paghi solo una volta per
                    sempre!</strong
                  >
                  <span style="display: none" id="scrittaRisparmio">
                    <strong>.</strong></span
                  >
                  <div class="after"></div>-->
                </div>
                <div class="price font-xl">
                  <span id="imponibileMsg" v-html="totalCost"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-confirmation">
      <AddMessageButton 
        :for-message-n="message.messageNumber + 1"
        @click="addAnotherMessage"
      />
      <button class="wa btn mx-1 mb-0 bg-gradient-success btn-md null my-4 mb-2" @click="goToCheckout()" style="line-height: 1.3333333;padding-top: 15px;font-size: 18px;height: 100px;background-image: linear-gradient(195deg, rgb(204, 0, 100), rgb(127, 14, 83));"><strong>CONTINUA<br>(SENZA IMPEGNO)<br><i class="material-icons-round opacity-10 fs-5">arrow_forward</i></strong></button>
    </div>
  </section>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import MaterialSwitch from "@/components/MaterialSwitch.vue";
// import { mapMutations } from "vuex";
import Radiobutton from "@/components/Radiobutton.vue";
import Checkbox from "@/components/Checkbox.vue";
import repository from "../api/repository";
import SpeakerResolver from "@/components/SpeakersResolver.vue";
import MessagesText from "../components/MessagesText.vue";
import MusicalBases from '../components/MusicalBases.vue';
import Message from '@/classes/Message.js';
import MessageValidator from '@/classes/MessageValidator.js';
import GoToCheckout from "@/components/GoToCheckout.vue";
import AddMessageButton from "../components/AddMessageButton.vue";
import Header from "../components/Header.vue";
import Input from "../components/Input.vue";
import MusicalBase from "../components/MusicalBase.vue";
// import { markRaw } from 'vue';

export default {
  name: "OrderPage",

  props: {
    message: {
      type: Message,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    }
  },

  components: {
    Radiobutton,
    Checkbox,
    SpeakerResolver,
    MessagesText,
    MusicalBases,
    GoToCheckout,
    AddMessageButton,
    Header,
    Input,
    MusicalBase
  },

  data() {
    return {
      messageData: this.message,
      error: null,
      previewedPrice: 0,
      price: 0,
      totalCost: "",
      quoteBaseInfo: null,
      actualDiscount: "ERRORE",
      startFrom: "ERRORE",
    };
  },
  async beforeMount() {
    this.setQuoteInfoBase();
  },

  methods: {
    // ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async setQuoteInfoBase() {
      var infoFromDb = (await repository.getOrderPageElements()).data;

      if (this.edit) {
        // Merge message with info from db
        var that = this;

        // Sex
        infoFromDb.sexes.forEach(function(obj) {
            obj.selected = (obj.id == that.message.sex);
        });

        infoFromDb.languages.forEach(function(obj) {
          obj.selected = that.message.selectedLanguages.includes(obj.id);
        });

        // Object.keys(infoFromDb.voices[this.message.sex]).forEach(function(obj) {
        that.message.selectedLanguages.forEach(function(language) {
          var choosenSpeaker = that.message.speakersByLanguages[language]
          infoFromDb.voices[that.message.sex][language].forEach(function (speaker) {
            speaker.selected = speaker.id == choosenSpeaker;
          })
        });
      }

      this.quoteBaseInfo = infoFromDb;
    },
    updateMessagesInformations(e) {
      this.updateGlobalPrice(e);
      this.updateTextMessagesByLanguage(e);
    },
    updateGlobalPrice(e) {
      this.messageData.realCost = e.total_cost;
      this.messageData.withoutDiscount = e.without_discount;
      var totalSplitted = e.total_cost.toString().split(".");
      this.totalCost = `<span>${totalSplitted[0]}</span><small>.${totalSplitted[1]} €</small></span>`;
    },
    updateSex(e) {
      this.messageData.sex = e;
    },
    updateType(e) {
      this.messageData.type = e;
    },
    updateLanguages(e) {
      // delete speakers and text
      this.messageData.speakersByLanguages = Object.fromEntries(e.map(col => [col, this.messageData.speakersByLanguages[col]]));
      this.messageData.messagesByLanguages = Object.fromEntries(e.map(col => [col, this.messageData.messagesByLanguages[col]]));
      this.messageData.selectedLanguages = e;
    },
    updateSpeakersByLanguage(e) {
      var speakerLanguage = Object.keys(e)[0];
      if (speakerLanguage) {
        this.messageData.speakersByLanguages[speakerLanguage] = e[speakerLanguage].speaker;
      }
    },
    updateTextMessagesByLanguage(e) {
      this.messageData.messagesByLanguages = e.messages;
      this.messageData.identifiedLanguage = e.identified_language;
    },
    updateMusicalBase(e) {
      this.messageData.hasMusicalBase = (e == 'true')
    },
    updateDurationMusicalBase(e) {
      this.messageData.durationMusicalBase = e;
    },
    setMusicalBase(e) {
      this.messageData.musicalBase = e;
    },
    goToCheckout() {
      if (this.validateMessage()) {
        this.storeMessage();
        this.$router.push({ path: '/checkout' });
      }
    },
    addAnotherMessage() {
      if (this.validateMessage()) {
        this.storeMessage();
        this.$router.push({ name: 'Quote', params: {messageNumber: (parseInt(this.message.messageNumber + 1))} });
      }
    },
    async storeMessage() {
      await this.$store.dispatch('setMessageOrder', this.messageData);
    },
    validateMessage() {
      var errors = this.getErrors()
      if (errors.length > 0) {
        this.buildHtmlErrors(errors);
        return false;
      }
      return true;
    },
    getErrors() {
      var messageValidator = new MessageValidator(this.quoteBaseInfo, this.messageData);
      return messageValidator.validate();
    },
    buildHtmlErrors(errors) {
      var errorsHtml = "";
      for (var error of errors) {
        errorsHtml += "<li>" + error + "</li>";
      }

      this.$swal({
        icon: 'info',
        title: '<strong>Errori rilevati nel messaggio!</strong>',
        html: "<ul>" + errorsHtml + "</ul>",
      })
    },
  }
};
</script>

<style>
#preventivo h3,
#preventivo h4 {
  font-weight: 400;
}

#preventivo .color-accent {
  color: #af0060;
}

#preventivo .intro {
  margin-bottom: 15px;
  letter-spacing: 0;
  line-height: 130%;
  font-size: 14px;
}

#preventivo hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 3px solid #c6d2df;
}

.text-price {
  display: flex;
  justify-content: flex-end;
}

.text-price .price {
  font-size: 24px;
}

.summary-band {
  width: 100%;
  box-sizing: content-box;
  margin-left: -15px;
  padding-left: 15px;
  background: #36475b;
  color: #fff;
  text-align: right;
}

.summary-band .summary-description {
  padding: 40px 0;
}

.font-lg,
h3 {
  font-size: 32px;
}
.font-lg,
.font-md-lg,
.font-xl,
.font-xxl,
h1,
h2,
h3 {
  font-weight: lighter;
  line-height: 130%;
  letter-spacing: -1px;
}

.font-sm,
h6 {
  font-size: 14px;
}

.boxSfondoMagenta {
  clear: both;
}

.testoSoddRimb {
  line-height: 100%;
}
.textAlignRight {
  text-align: right;
}
.sfondoMagenta {
  float: right;
  width: 218px;
  padding: 2px 5px;
  margin-top: 5px;
  background: #af0060;
  color: #fff;
  text-align: left;
}

element.style {
}
.summary-band .summary-price {
  padding: 40px 0;
  padding-right: 15px;
  text-align: right;
}

.finestraRisparmiare {
  position: absolute;
  top: -70px;
  left: 10px;
  width: 205px;
  height: 80px;
  padding: 10px 14px;
  margin-left: 20px;
  border: none;
  background: #c5d3e0;
  text-align: center;
  z-index: 1060;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  font-size: 14px;
  line-height: 1.4;
  color: #465a6e;
  opacity: 1;
  border-radius: 3px;
}

.summary-band .price {
  color: #fff;
}
.price.font-xl {
  font-size: 56px;
  line-height: 0.8;
}
.price {
  font-weight: bolder;
  color: #70849b;
}
.font-xl,
h2 {
  font-size: 44px;
}
.font-lg,
.font-md-lg,
.font-xl,
.font-xxl,
h1,
h2,
h3 {
  font-weight: lighter;
  line-height: 130%;
  letter-spacing: -1px;
}


.form-confirmation {
    padding: 40px 0;
    text-align: center;
    background: #e1ebf0;
}

.form-confirmation strong {
  font-weight: 700px;
}
</style>
