<template>
    <div class="card card-body mx-3 mx-md-4 mt-n1">
      <div class="row gx-4">  
        <div class="row">
          <div class="mt-4 col-md-6">
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ user.NOME }} {{ user.COGNOME }}</h5> 
                <p class="mb-0 font-weight-normal text-sm">{{ user.NAZIONE }}</p>
              </div>
            </div>
          </div>
          <div class="mt-4 col-md-6">
            <div class="col-auto my-auto">
              <div class="h-100">
                <span class="mb-1">{{ user.PREFISSO }} {{ user.TELEFONO }}</span>
                <p class="mb-1">{{ user.EMAIL }}</p>
              </div>
            </div>
          </div>
        </div>
        <form class="mt-5">
          <div class="row">
            <div class="col-md-6">
              <label for="person-name" class="form-label">NOME</label>
              <div class="input-group input-group-outline">
                <input v-model="user.NOME" class="form-control" id="person-name">
              </div>
            </div>
            <div class="col-md-6">
              <label for="person-surname" class="form-label">COGNOME</label>
              <div class="input-group input-group-outline">
                <input v-model="user.COGNOME" class="form-control" id="person-surname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="person-name" class="form-label">SETTORE</label>
              <div class="input-group input-group-outline">
                <input v-model="user.SETTORE" class="form-control" id="person-name">
              </div>
            </div>
            <div class="col-md-6">
              <label for="person-surname" class="form-label">EMAIL</label>
              <div class="input-group input-group-outline">
                <input v-model="user.EMAIL" class="form-control" id="person-surname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="person-name" class="form-label">TELEFONO</label>
              <div class="input-group input-group-outline">
                <input v-model="user.TELEFONO" class="form-control" id="person-name">
              </div>
            </div>
            <div class="col-md-6">
              <label for="person-surname" class="form-label">STATO</label>
              <div class="input-group input-group-outline">
                <input v-model="user.NAZIONE" class="form-control" id="person-surname">
              </div>
            </div>
            <div class="mt-3">
              <button style="float:right" type="submit" class="btn btn-primary">Salva</button>
            </div>
          </div>
        </form>
      </div>
    </div>
</template>
  
<script>
import repository from "@/api/repository"

  export default {
    name: "Profile",
    data() {
      return {
        user: {
          NOME: "",
          COGNOME: "",
          TELEFONO: "",
          NAZIONE: "",
          PREFISSO: "",
          EMAIL: "",
          SETTORE: "",
        }
      }
    },
    async beforeMount() {
      var { data } = (await repository.getMyDatas());
      this.user = data;
    }
  };
</script>
  