<template>
    <div class="cc-selector-checkbox" style="margin-top:20px;">
        <div v-if="!inHalf">
            <div 
                v-for="element in el"
                v-bind:key="element.id"
                class="checkbox-container"
            >
            <div style="">
                <input :id="element.id" type="checkbox" :value="element.id" v-model="picked" />
                <label :class="[picked.includes(element.id)? 'checked' : 'unchecked', 'drinkcard-cc']" :for="element.id"></label>
                </div>
                <span v-if="element.prefix" style="margin-left:10px;">
                    <span v-html="element.prefix"></span>
                </span>
                <div style="margin-left:10px;display: inline-block;vertical-align: middle;line-height: normal;">
                    {{ element.label }}
                </div>
                <div v-if="element.suffix">
                    <div v-html="element.suffix"></div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-6 bordered-column">
                <div 
                    v-for="element in el.slice(0, getHalf)"
                    v-bind:key="element.id"
                    class="checkbox-container"
                >
                <div style="">
                    <input :id="element.id" type="checkbox" :value="element.id" v-model="picked" />
                    <label :class="[picked.includes(element.id)? 'checked' : 'unchecked', 'drinkcard-cc']" :for="element.id"></label>
                    </div>
                    <span v-if="element.prefix" style="margin-left:10px;">
                        <span v-html="element.prefix"></span>
                    </span>
                    <div style="margin-left:10px;display: inline-block;vertical-align: middle;line-height: normal;">
                        {{ element.label }}
                    </div>
                    <div v-if="element.suffix">
                        <div v-html="element.suffix"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div 
                    v-for="element in el.slice(getHalf)"
                    v-bind:key="element.id"
                    class="checkbox-container"
                >
                <div style="">
                    <input :id="element.id" type="checkbox" :value="element.id" v-model="picked" />
                    <label :class="[picked.includes(element.id)? 'checked' : 'unchecked', 'drinkcard-cc']" :for="element.id"></label>
                    </div>
                    <span v-if="element.prefix" style="margin-left:10px;">
                        <span v-html="element.prefix"></span>
                    </span>
                    <div style="margin-left:10px;display: inline-block;vertical-align: middle;line-height: normal;">
                        {{ element.label }}
                    </div>
                    <div v-if="element.suffix">
                        <div v-html="element.suffix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        name: "CheckboxComponent",
        props: {
            el: {
                type: Array,
                required: true,
                default: () => []
            },
            inHalf: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        mounted() {
            this.setId();
        },

        data() {
            return {
                picked: [],
            }
        },

        computed: {
            getHalf() {
                return Math.ceil(this.el.length / 2);
            }
        },

        methods: {
            setId() {
                let preselectedElements = this.el.filter(o => o.selected === true);
                if (preselectedElements) {
                    var that = this;
                    preselectedElements.forEach(function(preselectedElement) {
                        that.picked.push(preselectedElement.id);
                    })

                    // TODO verify why it doesn't trigger watch picked
                    this.$emit("update", this.picked);
                }
            },
        },

        watch: {
            picked: {
                handler: function(newValue) {
                    this.$emit("update", newValue);
                }
            },
            el(newValue) {
                let preselected = newValue.find(o => o.selected === true);
                if (preselected) {
                    this.picked.push(preselected.id);
                }
            }
        }
    }
    </script>
    
    <style>
    .cc-selector-checkbox input{
        margin:0;padding:0;
        -webkit-appearance:none;
           -moz-appearance:none;
                appearance:none;
    }
    .cc-selector-checkbox .unchecked{background-image:url(https://www.telephonevox.com/vendor/telephonevox-tp/img/form-elements/checkbox-0.svg);}
    .cc-selector-checkbox .checked{background-image:url(https://www.telephonevox.com/vendor/telephonevox-tp/img/form-elements/checkbox-1.svg);}
    
    .cc-selector-checkbox input:active +.drinkcard-cc{opacity: .9;}
    .cc-selector-checkbox input:checked +.drinkcard-cc{
        -webkit-filter: none;
           -moz-filter: none;
                filter: none;
    }
    .drinkcard-cc{
        cursor:pointer;
        background-size:contain;
        background-repeat:no-repeat;
        display:inline-block;
        width: 19px;
        height: 19px;
        -webkit-transition: all 100ms ease-in;
           -moz-transition: all 100ms ease-in;
                transition: all 100ms ease-in;
    }
    
    /* Extras */
    a:visited{color:#888}
    a{color:#444;text-decoration:none;}
    p{margin-bottom:.3em;}
    
    .checkbox-container {
        display: flex;
        width: 100%;
        margin-top:15px;
    }
    
    .cc-selector-checkbox .checkbox-container:not(:last-child) {
        border-bottom: 1px solid #eee;
    }

    .bordered-column {
        border-right: 1px solid #c6d2df;
    }
    </style>