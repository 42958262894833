<template>
  <div class="card card-body mx-3 mx-md-4 mt-n1">
    <div class="row gx-4">  
      <div class="col-auto my-auto">
        <div class="h-100">
          <h4 class="mb-1">Preventivi da convalidare</h4>
        </div>
      </div>
      <div class="mt-4">
        <div class="mt-4">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  ID ORDINE
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  EMAIL CLIENTE
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  PREZZO
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  DATA ORDINE
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  AZIONE
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="quote in quotes" v-bind:key="quote.id">
                <td class="align-middle text-center text-sm">
                  <h6 class="mb-0 text-sm">{{ quote.id }}</h6>
                </td>
                <td class="align-middle text-center text-sm">
                  <h6 class="mb-0 text-sm">{{ quote.pending_order.datiFatturazione.email }}</h6>
                </td>
                <td class="align-middle text-center">
                  <h6 class="mb-0 text-sm">{{ getTotalCost(quote.pending_order.order) }}</h6>
                </td>  
                <td class="align-middle text-center">
                  <h6 class="mb-0 text-sm">{{  (new Date(quote.created_at)).toLocaleString("it-IT")  }}</h6>
                </td>
                <td class="align-middle" style="display:flex;justify-content: end;">
                  <button @click="showOrder(quote.id)" type="button" class="btn btn-info w-40" style="display:flex;"><i class="material-icons-round opacity-10 fs-15">remove_red_eye</i><span class="align-middle">Vedi Ordine</span></button>
                  <button @click="validateOrder(quote.id)" type="button" class="btn btn-success w-50 mx-1" style="display:flex;"><i class="material-icons-round opacity-10 fs-15">check_circle_outline</i><span class="align-middle">Convalida Ordine</span></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>   
      </div>
    </div>
  </div>
  <QuoteSummary :messages="this.messages" :openModal="showModal"/>
</template>

<script>

import repository from '../../../api/repository';
import QuoteSummary from './quoteModals/quoteSummary.vue';

export default {
    name: "quotesToValidate",
    data() {
        return {
            quotes: [],
            messages: [],
            showModal: false
        };
    },
    async beforeMount() {
        this.quotes = (await repository.getMyQuotes()).data;
    },

    methods: {
      showOrder(id) {
        this.showModal = !this.showModal
        var messages = this.quotes.find(x => x.id == id)?.pending_order.order;
        if (messages) {
          this.messages = messages;
        }
      },
      getTotalCost(orders) {
        var total = 0;
        for (const orderK in orders) {
          total = total + orders[orderK].realCost;
        }
        return total;
      },
      validateOrder(id) {
        this.$swal.fire({
          title: 'Sicuro di voler validare il messaggio?',
          text: "Convalida questo messaggio solo se hai ricevuto il pagamento da parte del tuo  cliente.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Convalida!",
          cancelButtonText: "Chiudi",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.$swal.showLoading()
            return repository.validateOrder(id)
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response.data;
              })
              .catch(error => {
                this.$swal.showValidationMessage(
                  `Non è stato possibile convalidare l'ordine. Dettagli dell'errore: ${error.response.data}`
                )
              })
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              "Convalidato!",
              result.value,
              "success"
            );
          }
        })
      }
    },

    components: { QuoteSummary }
}
</script>
