import { createStore } from "vuex";
import repository from "../api/repository"
// import Message from "@/classes/Message.js";

export default createStore({
  state: {
    api_key: sessionStorage.user ? JSON.parse(sessionStorage.getItem('tvox_partner_api_key')) : null,
    static_header_info: null,
    affiliate_has_configuration: false,
    cid: null,
    playedBase: 0,
    messagesOrder: [],
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showLoader: false,
    showFooter: false,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    currentAffiliateLogo: null,
    currentAffiliateNumber: null,
    affiliateCib: null
  },
  getters: {
    api_key: state => state.api_key,
    authenticated: state => state.api_key !== null,
    staticHeaderInfo: state => state.static_header_info,
    hasConfiguration: state => state.affiliate_has_configuration, 
    cid: state => state.cid,
    messagesOrder: state => state.messagesOrder,
    messageOrder: (state) => (messageNumber) => {
      return state.messagesOrder.find(message => message.messageNumber === messageNumber)
    },
    currentAffiliateLogo: state => state.currentAffiliateLogo,
    currentAffiliateNumber: state => state.currentAffiliateNumber,
    affiliateCib: state => state.affiliateCib
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      // state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    forceShowSidenav(state) {
      state.showSidenav = true;
      state.showNavbar = true;
      state.showFooter = true;
    },
    showLoader(state) {
      state.showLoader = true;
    },
    hideLoader(state) {
      state.showLoader = false;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },
    SET_API_KEY(state, api_key) {
      state.api_key = api_key;
    },
    SET_HEADER_INFO(state, static_header_info) {
      state.static_header_info = static_header_info;
    },
    SET_HAS_CONFIGURATION(state, has_configuration) {
      state.affiliate_has_configuration = has_configuration;
    },
    SET_CID(state, cid) {
      state.cid = cid;
    },
    SET_PLAYED_BASE(state, baseId) {
      state.playedBase = baseId
    },
    SET_MESSAGE_ORDER(state, messageOrder) {
      var index = state.messagesOrder.findIndex(el => el.messageNumber === messageOrder.messageNumber)
      if (index == -1) {
        state.messagesOrder.push(messageOrder);
      } else {
        state.messagesOrder[index] = messageOrder;
      }
    },
    SET_AFFILIATE_INFOS(state, affialiateInfos) {
      state.currentAffiliateLogo = affialiateInfos.logo;
      state.currentAffiliateNumber = affialiateInfos.number;
    },
    DELETE_MESSAGE_ORDER(state, messageNumber) {
      state.messagesOrder = state.messagesOrder.filter(el => el.messageNumber != messageNumber);
      state.messagesOrder.forEach((message, index) => {
        message.messageNumber = index + 1;
      })
    },
    SET_AFFILIATE_CHECKED(state, affiliateCib) {
      state.affiliateCib = affiliateCib;
    },
    EMPTY_BASKET(state) {
      state.messagesOrder = [];
    }
  },
  actions: {
    setAffiliateInfos({ commit }, affiliateInfos) {
      commit("SET_AFFILIATE_INFOS", affiliateInfos);
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    setPlayedId({ commit }, playedId) {
      commit("SET_PLAYED_BASE", playedId);
    },
    setMessageOrder({ commit }, message) {
      commit("SET_MESSAGE_ORDER", message);
    },
    deleteMessageOrder({ commit }, messageNumber) {
      commit("DELETE_MESSAGE_ORDER", messageNumber);
    },
    setAffiliateChecked({ commit }, affiliateChecked) {
      commit("SET_AFFILIATE_CHECKED", affiliateChecked);
    },
    async login({ commit }, user) {
      const { data } = await repository.login(user);
      commit('SET_API_KEY', data.api_key);
      commit('SET_HEADER_INFO', data.static);
      commit('SET_HAS_CONFIGURATION', data.has_configuration);
      commit('SET_CID', data.cid);
      sessionStorage.tvox_partner_api_key = JSON.stringify(data.api_key); 
    },
    async automaticLogin({ commit }, ak) {
      const { data } = await repository.automaticLogin(ak);
      commit('SET_API_KEY', data.api_key);
      commit('SET_HEADER_INFO', data.static);
      commit('SET_HAS_CONFIGURATION', data.has_configuration);
      commit('SET_CID', data.cid);
      sessionStorage.tvox_partner_api_key = JSON.stringify(data.api_key); 
    },
    async logout({ commit }) {
      await repository.logout();
      commit('SET_API_KEY', null);
      sessionStorage.removeItem('tvox_partner_api_key');
    },
    emptyBasket({ commit }) {
      commit('EMPTY_BASKET');
    }
  }
});
