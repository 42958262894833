<template>
    <div :style="{ height: height + 'px', width: width + '%' }"  class="message-card"></div>
</template>

<style scoped>
.message-card  {
    background-color: #ddd;
    animation: pulse-bg 0.5s infinite;
}

@keyframes pulse-bg {
    0% { background-color: #ddd; }
    50% { background-color: #d0d0d0; }
    100% { background-color: #ddd; }
  }
</style>
    
<script>

export default {
    props: {
        width:{
            type:Number,
            required:true
        },
        height: {
            type:Number,
            required:true
        }
    },
    name: "SkeletonTableBody"
}
</script>
    