<template>
    <div class="card card-body mx-3 mx-md-4 mt-n1">
      <div class="row gx-4">  
        <div class="col-auto my-auto">
          <div class="h-100">
            <h4 class="mb-1">Come funziona?</h4>
          </div>
        </div>
        <div class="mt-4">
          Grazie a questo sistema puoi vendere ai tuoi clienti i messaggi audio per il loro centralino sfruttando una versione “white label” di Telephone Vox, 
          cioè un sito che non porta il nostro nome, ma il tuo! Sei tu a vendere, sei tu ad incassare, mentre Telephone Vox produce per te.<br><br>
          
          <p>
            <b>COME SI CHIAMA IL SITO</b><br>
            Il sito si chiama “{{ $store.getters['cid'] }}.audiodemo.info” e mostra il logo della tua attività che puoi caricare nella sezione CONFIGURAZIONE
          </p><br>
          <p>
            <b>COME SI ACQUISTA</b><br>
            Attraverso il tuo sito ({{ $store.getters['cid'] }}.audiodemo.info) chiunque può acquistare messaggi audio personalizzati per il centralino
          </p><br>
          <p>
            <b>COME SI PAGA</b><br>
            I tuoi clienti pagheranno direttamente te usando i dati bancari che puoi indicare nell’area CONFIGURAZIONE. Stiamo lavorando per progettare e integrare ulteriori forme di pagamento che accrediteranno direttamente tuoi account finanziari (paypal/carta di credito)
          </p>
          <p>

            <b>CHI DECIDE IL PREZZO DEI MESSAGGI</b><br>
            Il prezzo puoi deciderlo tu attraverso l’area CONFIGURAZIONE: puoi stabilire di applicare il margine che preferisci rispetto il prezzo di listino pubblico di Telephone Vox
          </p>
          <p>
            <b>COME FUNZIONA</b><br>
            Ogni volta che un tuo cliente effettuerà un ordine riceverai un’email di conoscenza con il riepilogo dei suoi dati e le specifiche dell’ordine. Tu non dovrai fare nulla. Attraverso la sezione “DA CONFERMARE” potrai decidere se cancellare l’ordine, modificarlo o se metterlo in produzione, in tale ultimo caso Telephone Vox lo fatturerà a tuo nome alla fine del mese. Telephone Vox produrrà i messaggi ordinati e li consegnerà solo a te, che potrai quindi inoltrarli al cliente o installarli direttamente nel suo centralino.
          </p>
          <p>
            <b>I TUOI CLIENTI NON CONOSCONO TELEPHONE VOX</b><br>
            Grazie a questo sistema i tuoi clienti non verranno mai a conoscenza del sito ufficiale “Telephone Vox” ma navigheranno in un semplice sito che porterà solo il tuo nome.
          </p>
        </div>
      </div>
    </div>
</template>
  
<script>

import { mapMutations } from "vuex";

export default {
  name: "howItWorksComponent",

  props: {
    rdr: {
      type: String,
      required: false
    }
  },

  async beforeMount() {
    // this.toggleEveryDisplay();
    this.forceShowSidenav();

    if (this.rdr) {
      await this.$router.push(`/my_site/${this.rdr}`);
    }
  },
  methods:{
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig", "forceShowSidenav"]), 
  }
}
</script>
  