import axios from "axios";
import store from '../store';

let instance = axios.create({
    withCredentials: true
});

instance.defaults.baseURL = process.env.VUE_APP_BASE_URL;

instance.interceptors.request.use(request => {
    request.headers.common['Accept']       = 'application/json';
    request.headers.common['Content-Type'] = 'application/json';
    
    request.headers.common['Authorization'] = "Bearer " + store.getters['api_key']
    return request;
});

instance.interceptors.response.use(
    response => { return response },
    error => {
        if (error.response.status === 401) {
            sessionStorage.removeItem('user');
            window.location.reload();
        }
        else {
            // toast.error("Ops! Qualcosa è andato storto", { timeout: 2000 }); 
        }

        return Promise.reject(error);
    }
);

export default instance;