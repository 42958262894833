import { createRouter , createWebHistory } from 'vue-router'

import SignIn from "../views/SignIn.vue";
import AutomaticLogin from "../views/AutomaticLogin.vue";
import MySite from "../views/my_site/MySite.vue";
import MySiteHowItWorks from "../views/my_site/children/howItWorks.vue";
import MySiteConfiguration from "../views/my_site/children/configuration.vue";
import MySiteQuotesToValidate from "../views/my_site/children/quotesToValidate.vue";
import MySiteValidatedQuotes from "../views/my_site/children/validatedQuotes.vue";

import MyDatas from "../views/my_datas/MyDatas.vue";
import MyDatasProfile from "../views/my_datas/children/profile.vue";
import MyDatasBillingDatas from "../views/my_datas/children/billingDatas.vue";
import MyDatasAffiliationDatas from "../views/my_datas/children/affiliationDatas.vue";

import WordOfMouth from "../views/word_of_mouth/WordOfMouth.vue";
import WordOfMouthAccruedCommisions from "../views/word_of_mouth/children/AccruedCommissions.vue";
import WordOfMouthHowItWorks from "../views/word_of_mouth/children/HowItWorks.vue";
import WordOfMouthMyClients from "../views/word_of_mouth/children/MyClients.vue";
import WordOfMouthPromotionTools from "../views/word_of_mouth/children/PromotionTools.vue";

// import OrderPage from "../views/OrderPage.vue";
import MessagesRenderer from "../components/MessagesRenderer.vue";
import CheckoutPage from "../views/CheckoutPage.vue";

import NotFound from "@/components/NotFound.vue";

import middleware from './middleware';

const routes = [
  {
    path: '/my_site',
    name: 'MySite',
    component: MySite,
    beforeEnter: middleware.user,
    children: [
        {
            path: 'how_it_works/:rdr?',
            name: 'Come funziona il mio sito?',
            component: MySiteHowItWorks,
            beforeEnter: middleware.user,
            props: route => ({ rdr: route.params.rdr })
        },
        {
            path: 'configuration',
            name: 'Configurazione',
            component: MySiteConfiguration,
            beforeEnter: middleware.user,
        },
        {
            path: 'quotes_to_validate',
            name: 'Preventivi da convalidare',
            component: MySiteQuotesToValidate,
            beforeEnter: middleware.user,
        },
        {
          path: 'validated_quotes',
          name: 'Preventivi convalidati',
          component: MySiteValidatedQuotes,
          beforeEnter: middleware.user,
        }, 
    ]
  },
  {
    path: '/word_of_mouth',
    name: 'WordOfMouth',
    component: WordOfMouth,
    beforeEnter: middleware.user,
    children: [
        {
            path: 'accrued_commissions',
            name: 'Commissioni maturate',
            component: WordOfMouthAccruedCommisions,
            beforeEnter: middleware.user,
        },
        {
            path: 'how_it_works_w',
            name: 'Come funziona il passaparola ?',
            component: WordOfMouthHowItWorks,
            beforeEnter: middleware.user,
        }, 
        {
            path: 'my_clients',
            name: 'I tuoi clienti',
            component: WordOfMouthMyClients,
            beforeEnter: middleware.user,
        },
        {
            path: 'promotion_tools',
            name: 'Strumenti di promozione',
            component: WordOfMouthPromotionTools,
            beforeEnter: middleware.user,
        }
    ]
  },
  {
    path: '/my_datas',
    name: 'MyDatas',
    component: MyDatas,
    beforeEnter: middleware.user,
    children: [
        {
            path: 'profile',
            name: 'Il mio profilo',
            component: MyDatasProfile,
            beforeEnter: middleware.user,
        },
        {
            path: 'affiliation_datas',
            name: 'Dati affiliazione',
            component: MyDatasAffiliationDatas,
            beforeEnter: middleware.user,
        },
        {
            path: 'billing_datas',
            name: 'Dati fatturazione',
            component: MyDatasBillingDatas,
            beforeEnter: middleware.user,
        },
    ]
  },
  {
    path: '/',
    redirect: to => {// eslint-disable-line no-unused-vars
      return '/preventivo/1';
    },
  },
  {
      path: "/login",
      name: "SignIn",
      component: SignIn,
  },
  {
    path: "/automatic-login/:ak/:rdr",
    name: "AutomaticLoginIn",
    component: AutomaticLogin,
    props: route => ({ ak: route.params.ak, rdr: route.params.rdr })
  },
  {
    path: "/preventivo/:messageNumber?",
    name: "Quote",
    // beforeEnter: middleware.clientOfAffiliate, // TODO like this but for now leave it in bleah way
    beforeEnter: middleware.checkAffiliate,
    component: MessagesRenderer,
    props: route => ({ messageNumber: parseInt(route.params.messageNumber) })
  },
  {
    path: "/checkout",
    name: "Checkout",
    // Add middleware to know if messages in state
    component: CheckoutPage
  },
  { 
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
  }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
