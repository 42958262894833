<template>
    <Modal :show-modal="openModalData"  @close="openModalData = false">
        <template v-slot:header> </template>
        <template v-slot:body>
            <div v-if="messagesData.length > 0">
                <MessagesOrderSummary
                    :messages="messages"
                    :isLoadingNewPrices="isLoadingNewPrices"
                    :hideButtons="true"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import MessagesOrderSummary from '../../../../components/MessagesOrderSummary.vue';
import Modal from '../../../../components/Modal.vue';

export default {
    name: "quoteSummary",
    components: { Modal, MessagesOrderSummary },

    props: {
        messages: {
            Type: Array,
            required: true
        },
        openModal: {
            Type: Boolean,
            required: true
        }
    },

    data() {
        return {
            openModalData: false,
            messagesData: []
        }
    },

    watch: {
        openModal() {
            this.openModalData = !this.openModalData
        },
        messages(newV) {
            this.messagesData = newV;
        }
    }
}
</script>