<template>
  <form @submit.prevent="submitForm">
    <div class="card card-body mx-3 mx-md-4 mt-n1">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h4 class="mb-1">Configurazione</h4>
          </div>
        </div>
        <div class="mt-4">
          <div class="card">
            <div class="p-3 pt-0 text-left card-body">
              <div class="mb-0 w-100" style="display:flex;justify-content: space-between;">
                <h6>LOGO ATTIVITA’</h6>
                <h6>PREVIEW LOGO</h6>
              </div>
              <span class="text-md"
                >Carica qui il logo della tua attività assicurandoti sia di dimensioni <b>220 x 78</b> (pixels) e di formato <b>jpg o png</b>.</span
              >
              <img style="width:15%;float:right!important;" v-if="previewImageUrl" :src="previewImageUrl" />
              <div class="clearfix"></div>
              <hr class="my-3 horizontal dark" />
              <div style="position: relative;height:20vh;">
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload($event)" style="display:none"/>
                <div style="position:absolute;bottom:0;width:50%;display: flex;">
                  <button @click="$refs.file.click()" type="button" class="btn btn-info w-40" style="display:flex;"><i class="material-icons-round opacity-10 fs-15">upload_file</i>&nbsp;<span class="align-middle">Carica Logo</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="card">
            <div class="p-3 pt-0 text-left card-body">
              <h6 class="mb-0 text-left">TELEFONO ATTIVITA’</h6>
              <div class="input-group input-group-outline mt-1" style="width:100%">
                Specifica il numero di telefono da mostrare nel tuo sito di vendita messaggi: 
                <input
                  type="text"
                  class="form-control mx-2"
                  v-model="formData.telephone"
                  accept="image/png, image/jpeg"
                  style="width:20%!important;flex: 0 0 auto;margin-top:-5px;"
                />
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.telephone.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="card">
            <div class="p-3 pt-0 text-left card-body">
              <h6 class="mb-0 text-left">EMAIL RISPOSTE</h6>
              <div class="input-group input-group-outline mt-1" style="width:100%">
                <div class="input-group input-group-outline mt-1" style="width:100%">
                  Specifica qui l'indirizzo email al quale desideri ricevere eventuali risposte da parte dei clienti che ricevono i preventivi e gli ordini. 
                  <input
                    type="text"
                    class="form-control mx-2"
                    v-model="formData.emailReplyTo"
                    style="width:18%!important;flex: 0 0 auto;margin-top:-5px;"
                  />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.emailReplyTo.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="card">
            <div class="p-3 pt-0 text-left card-body">
              <h6 class="mb-0 text-left">IBAN</h6>
              <div class="input-group input-group-outline mt-1" style="width:100%">
                <div class="input-group input-group-outline mt-1" style="width:100%">
                  Specifica qui dove riceverai i pagamenti da parte dei clienti. 
                  <input
                    type="text"
                    class="form-control mx-2"
                    v-model="formData.iban"
                    style="width:30%!important;flex: 0 0 auto;margin-top:-5px;"
                  />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.iban.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="card">
            <div class="p-3 pt-0 text-left card-body">
              <h6 class="mb-0 text-left">PREZZO</h6>
              <span class="text-md">
                Prezzi TELEPHONE VOX<br>
                Prezzo di partenza di un messaggio da 50 caratteri applicato ai clienti finali – <b>54,93</b> euro.<br>
                Prezzo di partenza di un messaggio da 50 caratteri applicato a te rivenditore – <b>35,70</b> euro. (Sconto 35%)<br><br>
              
                <div class="percent input-group input-group-outline mt-1" style="width:100%">
                  Stabilisci l’entità del sovrapprezzo da applicare rispetto ai prezzi applicati a te rivenditore: 
                <input
                  type="number"
                  class="form-control mx-2"
                  v-model="formData.overcharge"
                  style="width:10%!important;flex: 0 0 auto;margin-top:-5px;"
                />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.overcharge.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>

                Prezzo di partenza di un messaggio da 50 caratteri applicato ai clienti finali – {{ overchargeClients }} euro.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-0">
        <button style="float:right" type="submit" class="btn btn-info">Salva</button>
      </div>
    </div>
  </form>
</template>

<script>
import repository from '../../../api/repository';
import { required, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Configuration",
  data() {
    return {
      formData: {
        logoFile: null,
        telephone: null,
        overcharge: 0,
        emailReplyTo: null,
        iban: null
      },
      overchargeClients: null,
      previewImageUrl: null,
    }
  },

  setup() {
    return { v$: useVuelidate() };
  },

  async beforeMount() {
    await this.setForm();
    this.ricalculateOverchargeClients();
  },  

  methods: {
    async submitForm() {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) {
        await repository.saveConfiguration(this.formData);
        this.$store.commit('SET_HAS_CONFIGURATION', true);
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Configurazione salvata',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        });
        await this.setForm();
      }
    },
    handleFileUpload(event){
      this.formData.logoFile = event.target.files[0];
      const file = event.target.files[0];
      this.previewImageUrl = URL.createObjectURL(file);
    },
    ricalculateOverchargeClients() {
      var newCost = 35.70 + (((this.formData.overcharge ?? 0) *(35.70))/100);
      this.overchargeClients = (Math.round(newCost * 100) / 100).toFixed(2);
    },
    async setForm()
    {
      var { data } = await repository.getConfiguration();
      if (data != null) {
        this.formData.telephone = data.telephone_to_show;
        this.formData.overcharge = data.percentage_overcharge;
        this.formData.emailReplyTo = data.email_reply_to;
        this.formData.iban = data.iban;
        this.previewImageUrl = process.env.VUE_APP_BASE_URL + "/" + data.logo_path + "?t=" + Date.now();  
      }
    }
  },

  validations() {
    return {
      formData: {
        telephone: {
            required: helpers.withMessage("Questo campo è obbligatorio",required),
        },
        overcharge: {
          required: helpers.withMessage("Questo campo è obbligatorio",required),
        },
        emailReplyTo: {
            required: helpers.withMessage("Questo campo è obbligatorio",required),
            email: helpers.withMessage("Inserire un indirizzo email valido", email),
        },
        iban: {
          required: helpers.withMessage("Questo campo è obbligatorio",required),
        }
      },
    };
  },

  watch: {
    "formData.overcharge": {
      handler() {
        this.ricalculateOverchargeClients()
      }
    }
  },
};
</script>


<style>
.percent::after {
  content: '%';
}

.error-msg {
  color:red;
  font-weight: bold;
}
</style>