export default class Message {
    constructor(messageNumber) {
        this.messageNumber = messageNumber;
    }
    sex;
    type = "umano";
    speakersByLanguages = [];
    messagesByLanguages = [];
    identifiedLanguage;
    selectedLanguages = [];
    messageNumber;
    hasMusicalBase;
    musicalBase;
    durationMusicalBase;
    realCost;
    withoutDiscount;
}