<template>
  <section class="default section-color correzioneBoxCarrello">
    <div class="banner container padding112">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="banner-text">
            <h2 class="titoloCarrello">Preventivo personalizzato</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section style="background-color: white;">
    <div class="container">
      <br>
      <MessageSummary />
      <hr />
      <div style="width:100%;text-align: center;">
        <AddMessageButton
          :for-message-n="numberMessages" 
          @click="addAnotherMessage"
        />
      </div>
      <form style="width:70%;margin:0 auto;"  class="mt-5" v-if="this.$store.getters['messagesOrder']?.length > 0">
        <span class="titoloRiepilogoMessaggio">DATI FATTURAZIONE</span>
        <div class="mb-3 my-3">
          <div class="d-inline-flex align-items-center">
            <label
              for="exampleInputEmail1"
              class="form-label"
              style="min-width: 100px !important"
              >EMAIL</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.email"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.email.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 my-3">
          <div class="d-inline-flex align-items-center">
            <label
              for="exampleInputEmail1"
              class="form-label"
              style="min-width: 100px !important"
              >FORMA GIURIDICA</label
            >
            <div class="input-group input-group-outline">
              <select
                v-model="datiFatturazione.formaGiuridica"
                class="form-control w-100"
              >
                <option value="" disabled selected>
                  Selezionare la forma giuridica
                </option>
                <option value="persona_fisica">Persona fisica</option>
                <option value="soggetto_p_iva">Soggetto con P.IVA</option>
              </select>
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.formaGiuridica
                  .$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3" v-if="isPhysicalPerson">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >CODICE FISCALE</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.codiceFiscale"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.codiceFiscale
                  .$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3" v-else>
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >PARTITA IVA</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.piva"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.piva
                  .$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >NOME</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.nome"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.nome.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3" v-if="isPhysicalPerson">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >COGNOME</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.cognome"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.cognome.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >STATO</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.stato"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.stato.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >PROVINCIA</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.provincia"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.provincia.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >INDIRIZZO SEDE</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.indirizzo"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.indirizzo.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >NUMERO CIVICO</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.numeroCivico"
                type="number"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.numeroCivico
                  .$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >CAP</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.cap"
                type="number"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.cap.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-inline-flex align-items-center">
            <label for="" class="form-label" style="min-width: 100px !important"
              >LOCALITA'</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="datiFatturazione.localita"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.datiFatturazione.localita.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <button
          :disabled="isSubmitted"
          type="button"
          @click="submitForm"
          class="btn btn-info my-3"
        >
          CONFERMA I DATI E PROCEDI ALL'ORDINE
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import MessageSummary from "./checkout/messageSummary.vue";
import { mapMutations } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, requiredIf } from "@vuelidate/validators";
import repository from "../api/repository";
import AddMessageButton from "../components/AddMessageButton.vue";
import { getTotalMessages } from "@/assets/js/utils.js";

export default {
  name: "CheckoutPage",

  components: {
    MessageSummary,
    AddMessageButton,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      datiFatturazione: {
        email: null,
        formaGiuridica: null,
        piva: null,
        nome: null,
        cognome: null,
        stato: null,
        provincia: null,
        indirizzo: null,
        numeroCivico: null,
        cap: null,
        localita: null,
        codiceFiscale: null,
      },
      numberMessages: 0,
      isSubmitted: false,
    };
  },
  async beforeMount() {
    this.calculateTotalMessages();
  },

  computed: {
    isPhysicalPerson() {
      return this.datiFatturazione.formaGiuridica != 'soggetto_p_iva';
    }
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    addAnotherMessage() {
      this.$router.push({ name: 'Quote', params: {messageNumber: (parseInt(getTotalMessages() + 1))} });
    },

    calculateTotalMessages() {
      this.numberMessages = getTotalMessages() + 1
    },

    async submitForm() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.isSubmitted = true;

        var completeOrder = {
          order: this.$store.getters["messagesOrder"],
          datiFatturazione: this.datiFatturazione,
          affiliateId: this.$store.getters['affiliateCib']
        };
   
        var result = await repository.placeOrder(completeOrder)
        .catch( () => { 
            return false;
        })

        if (!result) {
          this.$swal({
                toast: true,
                icon: 'error',
                title: "Qualcosa è andato storto! L'ordine non è stato salvato!",
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', this.$swal.stopTimer)
                  toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
              });
            return false;
        }
        
        this.$swal({
          icon: "success",
          title: "Il tuo ordine è stato salvato.",
          text:
            "Ti ringraziamo per questo ordine.\n Hai ricevuto una email riepilogativa.",
          type: "success",
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch("emptyBasket");
            this.$router.push({ path: "/preventivo" });
          }
        });
      }
    },
  },

  validations() {
    return {
      datiFatturazione: {
        email: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
          email: helpers.withMessage(
            "Inserire un indirizzo email valido",
            email
          ),
        },
        formaGiuridica: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        nome: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        cognome: {
          requiredIf: helpers.withMessage("Questo campo è obbligatorio", 
          requiredIf(() => {
            return this.isPhysicalPerson;
          })),
        },
        stato: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        provincia: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        indirizzo: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        numeroCivico: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        cap: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        localita: {
          required: helpers.withMessage(
            "Questo campo è obbligatorio",
            required
          ),
        },
        codiceFiscale: {
          requiredIf: helpers.withMessage("Questo campo è obbligatorio", 
          requiredIf(() => {
            return this.isPhysicalPerson;
          })),
        },
        piva: {
          requiredIf: helpers.withMessage("Questo campo è obbligatorio", 
          requiredIf((form) => {
            return form.formaGiuridica === 'soggetto_p_iva';
          })),
        }
      },
    };
  },
};
</script>

<style>
.form-control {
  border: 0.5px solid;
  border-color: #70849b;
  background: #f9f9f9;
}

.titoloRiepilogoMessaggio {
  font-size: 30px;
  color: #af0060;
  margin-top: 20px;
  line-height: 105%;
}

section.section-color {
    background: #3b7dc1;
    color: #fff;
    padding: 70px 0;
    padding: 10px 0;
}

.padding112 {
    padding-left: 112px;
    padding-right: 112px;
}
.banner {
    position: relative;
}

h2.titoloCarrello {
    font-size: 38px;
    padding: 50px 0;
    color:white;
}

.container.padding112 {
    padding-left: 112px;
    padding-right: 112px;
}

.banner {
    position: relative;
}
@media (min-width: 1200px)
.container {
    width: 1170px;
}
@media (min-width: 992px)
.container {
    width: 970px;
}
@media (min-width: 768px)
.container {
    width: 750px;
}
.container, .containerSottoMenu {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
</style>
