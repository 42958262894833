import { getItalianLanguageTitle } from '../assets/js/utils.js';

export default class MessageValidator {
    constructor(quoteByDatabase, messageBuildByUser) {
        this.#quoteByDatabase = quoteByDatabase;
        this.#messageBuildByUser = messageBuildByUser;
    }
    #quoteByDatabase;
    #messageBuildByUser;
    #errors = [];

    validate() {
        this.#validateSex();
        this.#validateType();
        this.#validateLanguages();
        this.#validateSpeakers();
        this.#validateTexts();
        this.#validateMusicalBases();

        return this.#errors;
    }

    #validateSex = function() {
        var sex = this.#messageBuildByUser.sex;
        var isValidSex = this.#quoteByDatabase.sexes.some(function(el) {
            return el.id === sex;
        });

        if (!isValidSex) {
            this.#errors.push("Sesso speaker non valido");
        }

        return true;
    }

    #validateType = function() {
        var type = this.#messageBuildByUser.type;
        var isValidType = this.#quoteByDatabase.types.some(function(el) {
            return el.id === type;
        });
        
        
        if (!isValidType) {
            this.#errors.push("Tipo speaker non valido");
        }

        return true;
    }

    #validateLanguages = function() {

        if (!this.#messageBuildByUser.selectedLanguages || 
            !Array.isArray(this.#messageBuildByUser.selectedLanguages) ||
            this.#messageBuildByUser.selectedLanguages.length <= 0) {
                this.#errors.push(`Selezionare almeno una lingua`);
        }


        for (const el of this.#messageBuildByUser.selectedLanguages) {
            var language = el
            var isValidLanguage = this.#quoteByDatabase.languages.some(function(el) {
                return el.id === language;
            });
            if (!isValidLanguage) {
                this.#errors.push(`Lingua ${getItalianLanguageTitle(language)} non valida`);
            }
        };

        return true;
    }

    #validateSpeakers = function() {
        for (const el of this.#messageBuildByUser.selectedLanguages) {

            var speaker = this.#messageBuildByUser.speakersByLanguages[el]

            if (!speaker) {
                this.#errors.push(`Speaker non valido!`);
                continue;
            }

            var isValidSpeakerId = this.#quoteByDatabase.voices[this.#messageBuildByUser.sex][el].some(function(el) {
                return el.id === speaker;
            });

            if (!isValidSpeakerId) {
                this.#errors.push(`Speaker non valido!`);
            }
        }
        return true;
    }

    #validateTexts = function() {
        for (const el of this.#messageBuildByUser.selectedLanguages) {
            if (!this.#messageBuildByUser.messagesByLanguages[el] || this.#messageBuildByUser.messagesByLanguages[el].trim().length === 0) {
                this.#errors.push(`Testo lingua ${getItalianLanguageTitle(el)} vuoto o non valido!`);
            }
        }
        return true;
    }

    #validateMusicalBases = function() {
        if (this.#messageBuildByUser.hasMusicalBase) {
            if (!this.#messageBuildByUser.durationMusicalBase) {
                this.#errors.push(`Specificare i secondi della base musicale`);
            }
            if (!this.#messageBuildByUser.musicalBase) {
                this.#errors.push(`Selezionare una base musicale`);
            }

            // Type of musical base that have only ids
            // TODO change with constants and probably mixins to validate
            var baseWithOnlyIds = ["ClassicMusic", "ModernMusic", "ChristmasMusic"];
            if (baseWithOnlyIds.includes(this.#messageBuildByUser.musicalBase.type)) {
                if (isNaN(this.#messageBuildByUser.musicalBase.value) || !this.#messageBuildByUser.musicalBase.value) {
                    this.#errors.push(`Selezionare una base musicale`);
                }
            } else {
                if (!this.#messageBuildByUser.musicalBase.value.title) {
                    this.#errors.push(`Specificare il titolo della base musicale`);
                }
                if (!this.#messageBuildByUser.musicalBase.value.author) {
                    this.#errors.push(`Specificare l'autore della base musicale`);
                }
                if (this.#messageBuildByUser.musicalBase.type == "Link" &&
                    !this.#messageBuildByUser.musicalBase.value.externalLink) {
                    this.#errors.push(`Specificare il link della base musicale`);
                }
            }
        }
        return true;
    }
}