<template>
    <template v-if="els.length > 0">
        <Radiobutton
            @update="updateMusicalBase"
            @update-component="updateDurationMusicalBase"
            :el="els"
        />
    </template>
</template>
    
<script>
import Input from "./Input.vue";
import Radiobutton from "@/components/Radiobutton.vue";
import { markRaw } from 'vue';

export default {
    name: "MusicalBaseComponent",

    components: {
        Input,
        Radiobutton
    },

    props: {
        hasMusicalBase: {
            type: Boolean,
            required: true
        },
        durationMusicalBase: {
            type: Number,
            required: false
        }
    },

    data() {
        return {
            els: []
        }
    },

    created() {
        this.els = [
            {
                id: 'false',
                label: 'No, il messaggio deve essere solo voce',
                selected: !this.hasMusicalBase,
            },
            {
                id: 'true',
                label: 'Si, lasciate che la base prosegua oltre il parlato per',
                suffix: 'secondi',
                component: markRaw(Input),
                componentProps: { type: 'number', value: this.durationMusicalBase, width: "50" },
                selected: this.hasMusicalBase,
            }
        ];
    },


    methods: {
        updateMusicalBase(e) {
            this.$emit('update', e);
        },
        updateDurationMusicalBase(e) {
            this.$emit('update-component', e);
        }
    }
    
}
</script>
    
<style>

</style>