<template>
  <Modal :show-modal="openModalData">
    <template v-slot:header> </template>
    <template v-slot:body>
      <small
        >Non hai trovato la base musicale che desideri? Nessun problema!<br />
        Per tuo conto acquisteremo la tua canzone preferita.<br />
        Essa sarà poi montata sul messaggio. Il servizio è gratuito.</small
      ><br /><br />

      <small
        ><b
          >Attenzione: In questo unico caso il diritto di riproduzione relativo
          all'opera musicale contenuta nei nostri messaggi audio sarà da noi
          assolto presso la società che tutela gli autori ed editori, ma la
          liceità della diffusione telefonica dell'opera in questione è
          subordinata al rilascio da parte della stessa società di autonoma
          licenza di diffusione. Se invece scegliete un brano dal menù indicato
          nulla sarà dovuto alle società che tutelano il diritto d'autore, i
          diritti fonografici e altri.</b
        ></small
      ><br /><br />

      <small
        >Se sei convinto di quanto sopra, scrivi qui il titolo e l'autore della
        musica che preferisci.</small><br><br>

      <form class="place-items-center">
        <div class="mb-3 my-3">
          <div class="d-inline-flex align-items-center w-100">
            <label
              for="exampleInputEmail1"
              class="form-label"
              style="min-width: 100px !important"
              >Titolo</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="formData.title"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div class="input-errors" v-for="(error, index) of v$.formData.title.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 my-3">
          <div class="d-inline-flex align-items-center w-100">
            <label
              for="exampleInputEmail1"
              class="form-label"
              style="min-width: 100px !important"
              >Autore</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="formData.author"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div class="input-errors" v-for="(error, index) of v$.formData.author.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 my-3">
          <div class="d-inline-flex align-items-center w-100">
            <label
              for="exampleInputEmail1"
              class="form-label"
              style="min-width: 100px !important"
              >Link</label
            >
            <div class="input-group input-group-outline">
              <input
                v-model="formData.externalLink"
                type="text"
                class="form-control"
                name=""
                style="width: 100%"
              />
              <div class="input-errors" v-for="(error, index) of v$.formData.externalLink.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 justify-center items-center">
            <button type="button" @click="submitForm" style="margin: 0 auto;float:right;" class="btn btn-info w-20">OK</button>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../components/Modal.vue";
import Base from "./base.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  extends: Base,

  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    Modal,
  },
  data() {
    return {
        formData: {
            title: "",
            author: "",
            externalLink: ""
        }
    }
  },
  methods: {
    submitForm() {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) {
        this.$emit("filled", this.formData);
        this.openModalData = false;
      }
    }
  },

  validations() {
    return {
      formData: {
        title: {
            required: helpers.withMessage("Questo campo è obbligatorio",required),
        },
        author: {
          required: helpers.withMessage("Questo campo è obbligatorio",required),
        },
        externalLink: {
          required: helpers.withMessage("Questo campo è obbligatorio",required),
        }
      },
    };
  },
};
</script>
