<template>
    <input
        :type="type"
        v-model="inputM"
        v-on:change="changed"
        :style="{ width: width + 'px' }"
    />
</template>

<script>
export default {
    name: "InputComponent",
    props: {
        type: {
            type: String,
            default: null,
            required: true,
        },
        value: {
            type: [String, Number],
            default: null,
            required: false
        },
        width: {
            type: [String],
            default: null,
            required: false
        }
    },

    data() {
        return {
            inputM: this.value
        }
    },

    methods: {
        changed() {
            this.$emit("updated", this.inputM);
        }
    }
}

</script>