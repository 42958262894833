<template>
  <div class="card card-body mx-3 mx-md-4 mt-n1">
    <div class="row gx-4">
      <div class="col-auto my-auto">
        <div class="h-100">
          <h4 class="mb-1">I tuoi clienti</h4>
        </div>
      </div>
      <div class="mt-4">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  ID UTENTE
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  NOME E COGNOME
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  NOME AZIENDA
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs text-center font-weight-bolder opacity-7"
                >
                  TIPOLOGIA
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="client in myClients" v-bind:key="client.id">
                <td class="align-middle text-center text-sm">
                  <h6 class="mb-0 text-sm">{{ client.id }}</h6>
                </td>
                <td class="align-middle text-center text-sm">
                  <h6 class="mb-0 text-sm">{{ client.name }}&nbsp;{{ client.surname }}</h6>
                  <!-- <span class="badge badge-sm bg-gradient-success">Online</span>-->
                </td>
                <td class="align-middle text-center">
                  <h6 class="mb-0 text-sm">{{ client.entrepriseName }}</h6>
                </td>  
                <td class="align-middle text-center">
                  <span class="badge badge-sm bg-gradient-success">{{  client.typology  }}</span>
                </td>    
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import repository from "@/api/repository.js"
export default {
  name: "MyClients",
  data() {
    return {
      myClients: []
    }
  },
  async beforeMount() {
    this.myClients = (await repository.getMyClients()).data;
  }
};
</script>
